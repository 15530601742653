import React, { useEffect, useState, useMemo, useContext } from "react";
import reducer, { getInitialState } from "./leads-reducer";
import { Auth } from "../../../rbac/rbac";
import { Dropdown } from "react-bootstrap";
import { EditLeadViewWithData } from "./edit-lead-view";
import { PROSPECT, MARKETING_ONLY } from "../../../utils/constants"
import { FaEnvelope, FaPen, FaTrashAlt, FaStar, FaBullhorn, FaMailBulk } from "react-icons/fa";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import Dialog from "./../../../components/dialog/dialog";
import routeConstants from "../../layout/routes";
import LeadFilters from "../forms/add-lead-filter-container";
import LeadConvertView from "./lead-convert-view";
import _ from "lodash";
import TableView, {
  SortableHeader,
  stringComparator,
  TextCell,
  ListCell,
  TextHeader,
  ActionCell,
  TextCellWithMapper,
  PhoneCell,
  DetailLinkCell,
  TooltipCell,
  includes,
  betweenDates,
  filterForList,
  dateComparator,
  EmailCell,
  buyerTwoIconCellObject,
} from "../../../components/new-table";
import "./dashboard-lead.css";
import { DashboardContext } from "../../../context";
import massMailLogo from "../../../images/icon-massEmail.svg";
import DeleteDialogBox from "../../../components/dialog/delete-dialog-box";
import SendMassEmail from "../../../components/dialog/send-email"
import PermanentDeleteConfirmation from "../../../components/dialog/permanent-delete";
import {
  getUpdatedParams,
  getCardParams,
  formatActivityDate,
  phoneDisplay,
  isBuyerTwo,
  getSubscribedEmails,
  getAllEmails
} from "../../../utils/helpers";

/** @module LeadsTableModule */

/**
 * This component is a table used to display all the leads. It contains pagination, searchbar, filtering,
 * mass-update and export to csv options. It has multiple hover-actions. It shows multiple columns displaying basic
 * information related to lead. Table uses reducer to maintain its state. It also accesses store to check if
 * table parameters are already stored or not.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of leads to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */

const LeadsTable = ({
  data,
  addLeadDeleteRequests,
  convertLeadToProspect,
  leadDataUpdated,
  selectedDivision,
  selectedCommunity,
  getTableParams: { tableParams },
  ...props
}) => {
  const { is_manager, is_divmgr } = useContext(Auth);
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.tableTitle, tableParams);
  const [massEmailSubmission, setMassEmailSubmission] = useState(false)
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    render,
    userPageCount,
    pageSize,
    Leads,
    loading,
    showDeleteConfirmation,
    isMassDelete,
    showConvertConfirmation,
    showEditBar,
    itemId,
    delete_lead_id,
    convertLeadId,
    deleteCustomerId,
    showLeadConvertDrawer,
    showPermanentDeletConfirmation,
    isPermDeleteSubmitted,
    editItem,
    showMassEmailModal,
    leadEmails,
    isMassEmailSubmitted,
  } = state;

  const [note, setNote] = useState("");
  // const handler = useRef(false);

  /** Refetch table data on any update like create, edit, delete */
  useEffect(() => {
    if (props.updated !== null) {
      data.refetch();
    }
  }, [props.updated]);

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, render: false, pageSize: cacheParams.limit },
      });
    }
    props.setPreviousCard({ variables: { title: currentCard.title } });
  }, [props.currentCard.title]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getLeads && data.getLeads.Leads) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          Leads: data.getLeads.Leads,
          loading: false,
          userPageCount: Math.ceil(data.getLeads.total_count / pageSize),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  /** Refetch table data on any update like create, edit, delete */
  useEffect(() => {
    if (leadDataUpdated) {
      dispatch({ type: "UPDATE", payload: { loading: true } });
      data.refetch();
    }
  }, [leadDataUpdated]);

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const notifyRefetch = () => {
    props.update();
  };

  /**
   * It is used to call the mutation to convert a lead to prospect,
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleLeadToProspect = () => {
    let convertLeadToProspectInput = [];
    let leadWithNoCommunity = false;

    data.getLeads.Leads.map((item) => {
      if (convertLeadId.includes(item.id) && item.communities.length > 0) {
        let lead_id = item.id;
        let leadToProspect = [];
        item.communities.forEach((community) => {
          leadToProspect.push({ community_id: community.id });
        });
        convertLeadToProspectInput.push({ lead_id, leadToProspect });
      } else if (convertLeadId.includes(item.id)) {
        leadWithNoCommunity = true;
      }
      return 0;
    });

    convertLeadToProspect({ variables: { input: convertLeadToProspectInput } })
      .then((res) => {
        if (res.data.convertLeadToProspect.code === 200) {
          let message = res.data.convertLeadToProspect.message;
          if (convertLeadToProspectInput.length >= 1 && leadWithNoCommunity) {
            message += "! Lead(s) with no community are not converted.";
          } else if (convertLeadToProspectInput.length === 0 && leadWithNoCommunity) {
            message = "Lead(s) with no community can not be converted.";
          }
          notifyRefetch();
          props.setNotificationProps({
            variables: { open: true, message: message },
          });
          dispatch({
            type: "UPDATE",
            payload: { showConvertConfirmation: false },
          });
        }
      })
      .catch((res) => {
        res.graphQLErrors.map((error) => {
          return error.message;
        });
      });
  };

  const onSaveFilter = (message) => {
    props.setNotificationProps({ variables: { open: true, message: message } });
  };

  /**
   * This function hanldes the dialog for sending mass 
   * emails TO
   * 
   * @param {Array} leadEmails Lead IDs
   */
  const handleMassEmail = (leadEmails) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showMassEmailModal: !showMassEmailModal,
        leadEmails
      },
    });
  }


  /**
   * It is used to show delete dialog and set the delete and customer id variables.
   * @param {number} itemId lead id
   * @param {number} customer_id customer id
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleDelete = (itemId, customer_id) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: !showDeleteConfirmation,
        delete_lead_id: itemId,
        deleteCustomerId: customer_id,
      },
    });
  };

  /**
   * It is used to show delete dialog and set the delete id variable.
   * @param {array} id selected lead ids
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleMassDelete = (id) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: !showDeleteConfirmation,
        isMassDelete: true,
        delete_lead_id: id,
      },
    });
  };

  /**
   * It is used to show/ hide convert dialog and set the delete and lead id variables.
   * @param {array} id selected lead ids
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleMassConvert = (id) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showConvertConfirmation: !showConvertConfirmation,
        convertLeadId: id,
      },
    });
  };

  /**
   * It is used to hide delete dialog.
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const hideDeleteDialog = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation, isMassDelete: false },
    });
  };

  /**
   * It is used to call the mutation to delete a lead(s).
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation, isMassDelete: false },
    });
    addLeadDeleteRequests({
      variables: { input: { lead_ids: delete_lead_id, note: note } },
    })
      .then((res) => {
        if (res.data.addLeadDeleteRequests.code === 200) {
          setNote("");
          notifyRefetch();
          props.setNotificationProps({
            variables: {
              open: true,
              message: res.data.addLeadDeleteRequests.message,
            },
          });
        }
      })
      .catch((res) => {
        res.graphQLErrors.map((error) => {
          return error.message;
        });
      });
  };

  /**
   * This function is used to change the ispermDeleteSubmitted.
   */
  const handlePermanentDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { isPermDeleteSubmitted: !isPermDeleteSubmitted },
    });
  };

  /**
   * This function is used by the Dialog as well as the send-email component.
   * It sets the value true when the Dialog is submitted and is resetted to
   * false in the send-email component. 
   * 
   * @param {Boolean} state Changes the state of massEmailSubmission 
   */
  const checkMassEmailSubmission = (state) => {
    setMassEmailSubmission(state)
  }

  /**
   * This function handles the submitted state for when mass
   * emails are sent
   */
  const handleMassEmailSubmitted = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        isMassEmailSubmitted: !isMassEmailSubmitted,
        showMassEmailModal: !showMassEmailModal
      },
    });
  };


  /**
   * It is used to when permanent delete has successfully done and it is used to show the success notification and refetch data and hides the permanent delete confirmation dialog.
   * @param {string} message message to be shown in notification bar
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const onPermanentDeletionSuccess = (message) => {
    dispatch({
      type: "UPDATE",
      payload: { loading: true, showPermanentDeletConfirmation: false },
    });
    notifyRefetch();
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
  };

  /**
   * It is used to render the permanent delete confirmation dialog.
   * @param {number} deletCustomerID customer id
   * @param {boolean} isPermDeleteSubmitted boolean that shows if permanent delete is submitted
   * @param {funciton} onPermanentDeletionSuccess its a function called and permanent delete is successfully performed
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const reConfirmationBody = (
    deleteCustomerId,
    isPermDeleteSubmitted,
    onPermanentDeletionSuccess
  ) => {
    return (
      deleteCustomerId && (
        <PermanentDeleteConfirmation
          customerId={deleteCustomerId}
          isSubmitted={isPermDeleteSubmitted}
          onSubmit={onPermanentDeletionSuccess}
        />
      )
    );
  };

  const onMassEmailSuccess = (message) => {
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
  }

  /**
   * This function is responsible to generate the entire content
   * of the Dialog. It renders the 'SendMassEmail' component
   * with an array of emails of selected Leads and a Boolean variable
   * to check the form submission state.
   * 
   * @param {Array} emails Lead Emails
   * @param {Boolean} checkSubmission Is form submitted
   * @param {Function} onFormSubmit Updated the states when form submitted successfully
   * @param {Function} onSubmitNotification Notifies user of the email submission
   * @param {Funciton} handleCheckSubmission Updates the status of checkSubmission  
   * @returns 
   */
  const sendMassEmailBody = (
    leadEmails
  ) => {
    return (
      leadEmails && (
        <SendMassEmail
          emails={leadEmails.split("%3B%20")}
          checkSubmission={massEmailSubmission}
          onFormSubmit={handleMassEmailSubmitted}
          onSubmitNotification={onMassEmailSuccess}
          handleCheckSubmission={checkMassEmailSubmission}
        />
      )
    )
  }

  /**
   * It is used to show the edit lead drawer and set the item id to the lead id and the edit item to the lead.
   * @param {Object} data contains information realted to the lead
   * @param {number} itemId lead id
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleEditOpen = (itemId, data) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showEditBar: true,
        itemId: itemId,
        editItem: data,
      },
    });
  };

  /**
   * It is used to close the edit lead drawer and reset the item id and edit item.
   * @param {Object} data contains information realted to the lead
   * @param {number} itemId lead id
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleEditClose = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        showEditBar: false,
        itemId: null,
        editItem: null,
      },
    });
  };

  /**
   * It is used to show the lead to marketing only/prospect drawer and set the item id to the lead id and the edit item to the lead.
   * @param {Object} data contains information realted to the lead
   * @param {Number} itemId lead id
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const handleLeadConvertDrawer = (itemId, data, type) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showLeadConvertDrawer: {
          show: true,
          type
        },
        itemId: itemId,
        editItem: data,
      },
    });
  };

  /**
   * It is used to close the lead to prospect drawer and reset the item id and edit item.
   * @param {Object} data contains information realted to the lead
   * @param {Number} itemId lead id
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const toggleSideBar = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        showLeadConvertDrawer: {
          show: !showLeadConvertDrawer.show,
          type: ""
        },
        itemId: null,
        editItem: null,
      },
    });
  };

  const leadsUpdate = (message, isLeadtoProspect) => {
    if (isLeadtoProspect) toggleSideBar();
    notifyRefetch();
    message &&
      props.setNotificationProps({
        variables: { open: true, message: message },
      });
  };

  const deleteConfirmationBody = () => {
    return <DeleteDialogBox setFunc={setNote} customer />;
  };

  /**
   * It is used to render the mass dropdown actions which contains 4 actions.
   * 
   * @param {Array} emails selected leads emails
   * @param {Array} ids selected leads ids
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   * @returns {JSX.Element}
   */
  const massDropDownActions = (emails, ids) => {
    let mailTo = "mailto:" + emails;
    let massMail = "mailto:?bcc=" + emails;
    return (
      <div>
        <div style={{ display: "none" }}>
          <a href={mailTo} id="send-lead-email">
            {""}
          </a>
        </div>
        <div style={{ display: "none" }}>
          <a href={massMail} id="send-mass-lead-email">
            {""}
          </a>
        </div>
        <Dropdown.Item
          eventKey="1"
          id="dropdown-1"
          onClick={() => document.getElementById("send-lead-email").click()}
        >
          <FaEnvelope id={"dropdown-icn-1"} /> Send Email To
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          id="dropdown-2"
          // onClick={() => handleMassEmail(emails)} // mass email using SendGrid (currently disabled)
          onClick={() => document.getElementById("send-mass-lead-email").click()} // mass email using Outlook
        >
          <FaMailBulk id={"dropdown-icn-1"} /> Send Email BCC
        </Dropdown.Item>

        <Dropdown.Item
          eventKey="3"
          id="dropdown-3"
          onClick={() => handleMassConvert(ids)}
        >
          {" "}
          <FaStar id={"dropdown-icn-3"} /> Convert to Prospect
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="4"
          id="dropdown-4"
          onClick={() => handleMassDelete(ids)}
        >
          {" "}
          <FaTrashAlt id="drop-down-icn-4" /> Delete
        </Dropdown.Item>
      </div>
    );
  };

  const ehancedFilter = (onSaveFilter, currentCard, ...rest) => (Component) => {
    const WrappedComponent = (props) => {
      return (
        <Component
          {...props}
          onSaveFilter={onSaveFilter}
          currentCard={currentCard}
          {...rest}
        />
      );
    };
    return WrappedComponent;
  };

  //Fetchmore and pagination code
  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:LeadsTableModule
   * @see {@link module:LeadsTableModule~LeadsTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }
    const columnItem = columns.find(
      (item) => item.accessor === tableProps.sortColumn.name
    );

    const pageCount = Math.ceil(
      data.getLeads.total_count / tableProps.pageSize
    );

    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: {
        id: tableProps.sortColumn.id,
        name: (columnItem && columnItem.sortName) || tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    if (pageCount !== userPageCount)
      callDispatch("UPDATE", {
        userPageCount: pageCount,
      });

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.tableTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   *  This defines columns of the table. It has multiple columns, displaying information of the lead. Most of
   * the columns are sortable. Clicking the first name column opens the details page of lead. Buyer two icon 
   * shows up as the first column if the lead has buyer 2 data as well.

   * @constant
   * @memberof module:LeadsTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: buyerTwoIconCellObject,
      },
      {
        id: "1",
        header: "First Name(s)",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => routeConstants.LEAD_DETAIL,
        },
      },
      {
        id: "2",
        header: "Last Name",
        accessor: "last_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "3",
        header: "Lead Notes",
        accessor: "other_notes",
        component: SortableHeader,
        comparator: stringComparator,
        filter: includes,
        cell: { component: TooltipCell },
      },
      {
        id: "4",
        header: "Email(s)",
        accessor: "primary_email_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: EmailCell,
          check: "primary_email_1_status",
          callback: getAllEmails
        },
      },
      {
        id: "5",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "6",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        sortable: false,
        filter: includes,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "7",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "8",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "9",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "10",
        header: "Mailchimp 2 Subscription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field) ? field[accessor] ? "Subscribed" : "Unsubscribed" : "",
        cell: { component: null },
      },
      {
        id: "11",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "12",
        header: "Community(ies)",
        accessor: "communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => field[accessor].map(({ name }) => name),
        filter: filterForList,
        cell: {
          component: ListCell,
          mapper: (field) =>
            field.map((item) => <div key={item.id}>{item.name}</div>),
        },
      },
      {
        id: "13",
        header: "Division",
        accessor: "divisions",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => field[accessor].map(({ name }) => name),
        filter: filterForList,
        cell: {
          component: ListCell,
          mapper: (field) =>
            field.map((item) => <div key={item.id}>{item.name}</div>),
        },
      },
      {
        id: "14",
        header: "Lead Source",
        accessor: "customer_origination_area.name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: TextCell,
          mapper: (field, accessor) => field?.customer_origination_area?.name || "———",
        },
      },
      {
        id: "15",
        header: "Last Activity",
        accessor: "last_activity",
        component: TextHeader,
        sortable: false,
        cell: {
          component: TooltipCell,
        },
      },
      {
        id: "16",
        header: "Last Activity Date",
        accessor: "last_activity_datetime",
        component: SortableHeader,
        comparator: dateComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
      {
        id: "17",
        header: "Date Created",
        accessor: "created_at",
        component: SortableHeader,
        filter: betweenDates,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
    ],
    [currentCard.filter]
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:LeadsTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Convert To Marketing Only",
        icon: FaBullhorn,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleLeadConvertDrawer(row.id, row, MARKETING_ONLY);
        },
      },
      {
        tooltip: "Convert To Prospect",
        icon: FaStar,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleLeadConvertDrawer(row.id, row, PROSPECT);
        },
      },
      {
        tooltip: "Edit",
        icon: FaPen,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleEditOpen(row.id, row);
        },
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id, customer_id }) => {
          handleDelete(id, customer_id);
        },
      },
    ],
    []
  );

  /**
   * This is an object that defines table title, and massActions and filters for the table.
   *
   * @constant
   * @memberof module:LeadsTableModule
   */
  const tableOptions = {
    title: currentCard.tableTitle,
    massActions: {
      render: (getSelectedRows) => {
        let emails = getSubscribedEmails(getSelectedRows());
        let ids = getSelectedRows().map((x) => x.id);
        return massDropDownActions(emails, ids);
      },
    },
    FilterItems: ehancedFilter(onSaveFilter, currentCard)(LeadFilters),
  };

  return (
    <div className="col-md-12 col-12 p-0">
      <Dialog
        show={showDeleteConfirmation}
        onHide={hideDeleteDialog}
        title="Delete Confirmation"
        body={deleteConfirmationBody()}
        click={handleDeleteConfirmation}
        isPermanentDelete={!isMassDelete && is_manager() || is_divmgr()}
        permanentDeleteClick={() =>
          dispatch({
            type: "UPDATE",
            payload: {
              showDeleteConfirmation: false,
              showPermanentDeletConfirmation: true,
            },
          })
        }
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showConvertConfirmation}
        onHide={() => {
          dispatch({
            type: "UPDATE",
            payload: { showConvertConfirmation: false },
          });
        }}
        title="Convert Lead(s) Confirmation"
        body="Are you sure you want to Convert Lead(s) to Prospect?"
        click={handleLeadToProspect}
        clickname="YES"
        closename="NO"
      />

      <Dialog
        show={showPermanentDeletConfirmation}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showPermanentDeletConfirmation: false },
          })
        }
        title="Re-Confirmation"
        body={reConfirmationBody(
          deleteCustomerId,
          isPermDeleteSubmitted,
          onPermanentDeletionSuccess
        )}
        click={handlePermanentDeleteConfirmation}
        clickname="Permanently Delete"
        closename="NO"
      />

      <Dialog
        show={showMassEmailModal}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showMassEmailModal: !showMassEmailModal },
          })
        }
        title="Send Email To"
        body={sendMassEmailBody(
          leadEmails
        )}
        click={() => checkMassEmailSubmission(true)}
        clickname="Send Email"
        closename="Cancel"
        size="lg"
      />

      <FadeIn show={render}>
        <div className="table-leads">
          <TableView
            columns={columns}
            data={Leads}
            tableOptions={tableOptions}
            loading={loading}
            hoverActions={hoverActions}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getLeads ? data.getLeads.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            searchTerm={state.search}
            sortColumn={state.order}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}

      {showEditBar && (
        <EditLeadViewWithData
          show={showEditBar}
          id={itemId}
          update={leadsUpdate}
          close={handleEditClose}
        />
      )}

      {showLeadConvertDrawer.show && (
        <LeadConvertView
          show={showLeadConvertDrawer.show}
          id={itemId}
          onSubmit={(msg) => leadsUpdate(msg, true)}
          leadData={editItem}
          close={toggleSideBar}
          type={showLeadConvertDrawer.type}
        />
      )}
    </div>
  );
};

export default LeadsTable;