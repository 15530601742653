import React, { useState, useEffect, useCallback, useContext } from "react";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Form, Field, withFormik, FieldArray } from "formik";
import { FaTimes, FaBan } from "react-icons/fa";
import { Auth } from "../../../rbac/rbac";
import { addProspectSchema, prospectForm } from "./prospect-form-schema";
import AddUpdateFlagForm from "./upsert-flag-form-container";
import { DetailsSectionComponent } from "../../realtor/details/registration-detail";
import Dialog from "../../../components/dialog/dialog";
import TextField from "../../../components/fields/text-field";
import TextAreaField from "../../../components/fields/text-area";
import LoadingWrapper from "../../../components/loading-wrapper";
import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button/index";
import DatePickerView from "../../../components/fields/date-picker";
import ErrorFocus from "../../../components/error-focus/error-focus";
import CheckboxField from "../../../components/fields/checkbox-field";
import AddEmailField from "../../../components/fields/add-email-field";
import FlagIcon from "../../../components/prospect-flag/prospect-flag-icon";
import MultiSelectField from "../../../components/fields/multi-select-field";
import ScoreSelectField from "../../../components/fields/score-select-field";
import ScheduleChart from "../../../components/schedule-chart/schedule-chart";
import { SearchableRealtorSelect } from "../../../components/agencies-creatable-select";
import { DefaultQuery } from "../../../graphql/default-query";
import {
  GET_AVAILABLE_HOMESITES,
  GET_AVAILABLE_OTHERS,
  GET_OTHERVALUES,
  GET_HOMESITES,
  GET_CSM,
  GET_AVAILABLE_FLOORPLANS,
  GET_COMMUNITIES,
  GET_CSM_AVAILABILITY_TIMESLOTS,
} from "../../../graphql/queries";
import {
  isNotNULL,
  phoneDisplay,
  getScoreColorList,
  getMwcList,
  formatDate,
} from "../../../utils/helpers";
import * as constants from "../../../utils/constants";
import "./prospect-forms.css";

// this form is being used for create prospect edit lead and also create prospect from webform
/**
 * This component is responsible to render the create edit form of the prospect. It has text fields, text area, select, time area, score, add email, checkbox fields.
 * First name, last name and either primary or cell phone must be filled to submit the form. Primary emails of buyer one and 2 mst not be similar. Primary
 * email must not match any of the secondary emails. These are the most important validations of this form. On form
 * submit form-data it checks wether to create, edit, convert or recover, and it perfomrs the appropriate mutation accordingly.
 *
 * @param {Object} props formik-bag, and query/mutations passed from the container
 * @param {Object} props.data data formatted in the form of schema
 */
const ProspectForm = (props) => {
  const {
    values,
    status,
    editForm,
    getTitleCustomizations: { titleCustomizations },
    getOriginationAreas,
    convertFormType,
    setFieldValue,
    convertForm,
    recoverForm,
    inActiveCommunities,
    registration,
    dashboardForm,
  } = props;
  const { is_csm } = useContext(Auth);

  let dropdownCommunities = [];
  const [primarySecondaryClicked1, setPrimarySecondaryClicked1] = useState({});
  const [primarySecondaryClicked2, setPrimarySecondaryClicked2] = useState({});
  const [primaryClicked, setPrimaryClicked] = useState({});
  const [timeFilterType, setTimeFilterType] = useState("AM");
  const [selectedCommunities, setSelectedCommunities] = useState([
    { key: "", value: "" },
  ]);

  const handleCommunityChange = (id) => {
    if (id.value === null) {
      setSelectedCommunities([]);
    } else {
      id.value = parseInt(id.value.split("__")[0]);
      const filteredCommunities = selectedCommunities.filter(
        (community) => community.key !== id.key
      );
      setSelectedCommunities([...filteredCommunities, id]);
    }
  };

  const findAndSetCustomerOrigination = useCallback(
    (toFind, originationList) => {
      let result = originationList.find((item) => {
        return item.name === toFind;
      });
      result && setFieldValue("customer_origination_area_id", result.id);
    },
    [setFieldValue]
  );

  const handleTimeSlotSelection = (
    { meetingStartDateTime, meetingEndDateTime },
    csm_data,
    index
  ) => {
    props.setFieldValue(`Prospects[${index}].csm_id`, csm_data.id);
    props.setFieldValue(
      `Prospects[${index}].Prospect_Appointments.start_datetime`,
      new Date(meetingStartDateTime)
    );
    props.setFieldValue(
      `Prospects[${index}].Prospect_Appointments.end_datetime`,
      new Date(meetingEndDateTime)
    );
  };

  const isCSMDisabled = (index) => {
    if (values.Prospects[index].community_id) {
      if (convertForm && values.Prospects[index].add_appointment) {
        return true;
      }
      return false;
    }
    return true;
  };

  const handleMergeBody = () => {
    return (
      <div>
        {status.showAddProspectErrorMessage}
        {status.showAddProspectErrorLeadCommunities?.length > 0 && (
          <div>
            <p>This Prospect exists as a Lead in the following communities:</p>
            {status.showAddProspectErrorLeadCommunities.map((item, index) => (
              <strong key={index}>{item.name}</strong>
            ))}
          </div>
        )}

        {status.showAddProspectErrorProspectCommunities?.length > 0 && (
          <div>
            <p>
              This prospect exists as a Prospect in the following communities:
            </p>
            {status.showAddProspectErrorProspectCommunities.map(
              (item, index) => (
                <strong key={index}>{item.name}</strong>
              )
            )}
          </div>
        )}
        <p>Do you want to merge?</p>
      </div>
    );
  };

  const handleCancelMerge = () => {
    props.setStatus({
      showAddProspectError: false,
      showAddProspectErrorMessage: null,
      showAddProspectErrorLeadCommunities: null,
      showAddProspectErrorProspectCommunities: null,
    });
  };

  const handleCloseArchiveDialog = () => {
    props.setStatus({
      showArchivedProspectError: false,
      showArchivedProspectErrorMessage: null,
    });
  };

  const handleMerge = () => {
    let submitValues = Object.assign({}, values);

    /* Normalize input state according to mutation input format */
    submitValues = normalizeData(submitValues);
    const submitInputs = JSON.parse(JSON.stringify(submitValues));

    submitInputs.Prospects.forEach((prospect, idx) => {
      const {
        Prospect_Appointments,
        Prospect_Follow_Ups,
        add_appointment,
        add_follow_up,
        ...rest
      } = prospect;

      if (add_appointment || add_follow_up) {
        submitInputs.Prospects[idx] = setInput(prospect, props);
      } else {
        submitInputs.Prospects[idx] = rest;
      }

      submitInputs.Prospects[idx].community_id = parseInt(
        submitInputs.Prospects[idx].community_id.split("__")[0]
      );
    });

    props
      .addProspectDuplicate({
        variables: {
          input: submitInputs,
          registration_id: props.registration_id,
        },
      })
      .then((res) => {
        if (res.data.addProspectDuplicate.code === 200) {
          props.onSubmit();
          props.setNotificationProps({
            variables: {
              open: true,
              message: res.data.addProspectDuplicate.message,
            },
          });
        }
      });

    handleCancelMerge();
  };

  // callback function to clear community-specific fields on changing division
  const fieldsToClearOnDivisionSelect = (data, division_id) => {
    let fields = [];

    data.Prospects &&
      data.Prospects.forEach((item, index) => {
        if (division_id !== parseInt(item.community_id?.split("__")[1])) {
          fields.push({
            fieldName: `Prospects[${index}].community_id`,
            value: "",
          });
          fields.push({
            fieldName: `Prospects[${index}].home_sites`,
            value: [],
          });
          fields.push({
            fieldName: `Prospects[${index}].other_dropdown_values`,
            value: [],
          });
          fields.push({ fieldName: `Prospects[${index}].csm_id`, value: null });
          fields.push({
            fieldName: `Prospects[${index}].floor_plans`,
            value: [],
          });
          fields.push({
            fieldName: `Prospects[${index}].rank_id`,
            value: null,
          });
        }
      });

    return fields;
  };

  useEffect(() => {
    const communityIdList = values.Prospects.map((prospect, idx) => {
      return {
        key: `Prospects[${idx}].community_id`,
        value: parseInt(prospect.community_id.split("__")[0]),
      };
    });

    setSelectedCommunities(communityIdList);
    // display schedule appointment tab by default if accessing form from dashboard
    dashboardForm && props.setFieldValue(`Prospects[0].add_appointment`, true);
  }, []);

  useEffect(() => {
    setSelectedCommunities([]);
  }, [values.division_id]);

  useEffect(() => {
    if (getOriginationAreas?.getAllCustomer_Origination_Areas) {
      if (convertFormType === "REALTOR REGISTRATION")
        findAndSetCustomerOrigination(
          constants.REALTOR_ORIGINATION_SOURCE,
          getOriginationAreas.getAllCustomer_Origination_Areas
        );
      else if (convertFormType === "WEBFORM")
        findAndSetCustomerOrigination(
          constants.WEB_ORIGINATION_SOURCE,
          getOriginationAreas.getAllCustomer_Origination_Areas
        );
    }
  }, [getOriginationAreas, convertFormType, findAndSetCustomerOrigination]);

  return (
    <Form className={"form-horizontal"} noValidate>
      {editForm ? null : (
        <>
          <Dialog
            show={status ? status.showAddProspectError : false}
            size="md"
            title="Creation Issue"
            onHide={handleCancelMerge}
            body={status ? handleMergeBody() : null}
            click={handleMerge}
            clickname="YES"
            closename="NO"
          />
          <Dialog
            show={status ? status.showArchivedProspectError : false}
            size="md"
            title="Creation Issue"
            onHide={handleCloseArchiveDialog}
            body={status ? status.showArchivedProspectErrorMessage : null}
            click={handleCloseArchiveDialog}
            clickname="OK"
          />
        </>
      )}
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Prospect</div>
            <div className="form_TitleBig">
              <div className="d-flex justify-content-center align-items-center">
                {props.inActiveCsm || inActiveCommunities?.length ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2022 }}>
                        {props.inActiveCsm && inActiveCommunities?.length
                          ? "Inactive CSM and Community: Please select from options for empty fields"
                          : props.inActiveCsm
                            ? "Inactive CSM: Please select from options for empty CSM field"
                            : "Inactive Community: Please select from options for empty community fields"}
                      </Tooltip>
                    }
                  >
                    <FaBan size={18} className="td-warning" />
                  </OverlayTrigger>
                ) : null}
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                  }}
                >
                  {editForm
                    ? `${values.first_name_1} ${values.last_name_1}`
                    : "Create New"}
                </h2>
                {editForm && (
                  <FlagIcon
                    FlagForm={AddUpdateFlagForm}
                    id={values.id}
                    red_flag={values.red_flag}
                    refetchEditData={props.refetchEditData}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex pr-md-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                onClick={props.close}
                btnValue="CANCEL"
                className="ml-sm-0"
              />
              <CustomButton
                type="submit"
                disabled={props.isSubmitting}
                btnValue={editForm ? "UPDATE" : "CREATE"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      {registration && (
        <div className={"detail-background"}>
          <div style={{ margin: "0px 1.1rem" }}>
            <DetailsSectionComponent registration={registration} />
          </div>
        </div>
      )}
      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#1</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_1"
              id="first_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_1"
              id="last_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_1"
                id="primary_email_1"
                type="text"
                disable={props.data && props.data.disable_primary_email_1}
                refFields={[
                  {
                    value: primarySecondaryClicked1,
                    setter: setPrimarySecondaryClicked1,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                required={!values.formatted_cell_phone_1 || false}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_1"
                id="formatted_cell_phone_1"
                isCellPhone={true}
                type="text"
                required={!values.primary_email_1 || false}
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_1"
              id="secondary_emails_1"
              type="text"
              initValue={
                editForm || recoverForm ? values.secondary_emails_1 : []
              }
              refFields={[
                {
                  value: primarySecondaryClicked1,
                  setter: setPrimarySecondaryClicked1,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        {!dashboardForm ? (
          <>
            <div className="form-row">
              <div className="form-section">
                <h5>Buyer#2</h5>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <Field
                  label="First Name"
                  name="first_name_2"
                  id="first_name_2"
                  type="text"
                  component={TextField}
                />
              </div>
              <div className="col-md-6">
                <Field
                  label="Last Name"
                  name="last_name_2"
                  id="last_name_2"
                  type="text"
                  component={TextField}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <div className="col-md-12 padding-less">
                  <Field
                    label="Primary Email"
                    name="primary_email_2"
                    id="primary_email_2"
                    disable={props.data && props.data.disable_primary_email_2}
                    type="text"
                    refFields={[
                      {
                        value: primarySecondaryClicked2,
                        setter: setPrimarySecondaryClicked2,
                      },
                      { value: primaryClicked, setter: setPrimaryClicked },
                    ]}
                    component={TextField}
                  />
                </div>
                <div className="col-md-6 padding-less">
                  <Field
                    label="Cell #"
                    name="formatted_cell_phone_2"
                    id="formatted_cell_phone_2"
                    isCellPhone={true}
                    type="text"
                    component={TextField}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <Field
                  label="Secondary Email(s)"
                  name="secondary_emails_2"
                  id="secondary_emails_2"
                  type="text"
                  initValue={
                    editForm || recoverForm ? values.secondary_emails_2 : []
                  }
                  refFields={[
                    {
                      value: primarySecondaryClicked2,
                      setter: setPrimarySecondaryClicked2,
                    },
                  ]}
                  component={AddEmailField}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <Field
                  label="Address"
                  name="street_address"
                  id="street_address"
                  type="text"
                  component={TextField}
                />
              </div>
              <div className="col-md-3">
                <Field
                  label="City"
                  name="city"
                  id="city"
                  type="text"
                  component={TextField}
                />
              </div>
              <div className="col-md-3">
                <Field
                  label="State"
                  name="state_id"
                  id="state_id"
                  type="text"
                  component={SelectField}
                >
                  <option value="">Select...</option>
                  {props.getAllStates.getAllStates &&
                    props.getAllStates.getAllStates.map((state, index) => (
                      <option key={index} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                </Field>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-2">
                <Field
                  label="Zip"
                  name="zip"
                  id="zip"
                  type="text"
                  component={TextField}
                />
              </div>
              <div className="col-md-4">
                <Field
                  label="Realtor"
                  name="realtor_id"
                  id="realtor_id"
                  disabled={!!props.registration_id}
                  component={SearchableRealtorSelect}
                />
              </div>
              <div className="col-md-3">
                <Field
                  label="Prospect Source"
                  name="customer_origination_area_id"
                  id="customer_origination_area_id"
                  component={SelectField}
                  required={true}
                >
                  <option value="">Select...</option>
                  {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
                    props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                      (option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                </Field>
              </div>
              <div className="col-md-3">
                <Field
                  label="Division"
                  name="division_id"
                  id="division_id"
                  required={true}
                  clearFieldsCallback={fieldsToClearOnDivisionSelect}
                  component={SelectField}
                >
                  <option value="">Select...</option>
                  {props.getAllDivisions.getAllDivisions &&
                    props.getAllDivisions.getAllDivisions.map(
                      (option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                </Field>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <Field
                  label="OSC Notes"
                  name="osc_notes"
                  id="osc_notes"
                  type="text"
                  rows={5}
                  disable={is_csm()}
                  component={TextAreaField}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="form-row">
            <div className="col-md-3">
              <Field
                label="Division"
                name="division_id"
                id="division_id"
                required={true}
                clearFieldsCallback={fieldsToClearOnDivisionSelect}
                component={SelectField}
              >
                <option value="">Select...</option>
                {props.getAllDivisions.getAllDivisions &&
                  props.getAllDivisions.getAllDivisions.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </Field>
            </div>
          </div>
        )}
        <div className="form-section">
          <h5>Community(s)</h5>
        </div>
        <FieldArray
          name="Prospects"
          render={(arrayHelpers) => (
            <>
              {values.Prospects &&
                typeof Array.isArray(values.Prospects) &&
                values.Prospects.map((pr, index) => (
                  <div className="form-community" key={index}>
                    <div className="row form_deleteButtonRow">
                      <CustomButton
                        className="icon-btn btn-icon"
                        onClick={() =>
                          values.Prospects.length > 1 &&
                          arrayHelpers.remove(index)
                        }
                      >
                        <FaTimes size={14} style={{ color: "white" }} />
                      </CustomButton>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <DefaultQuery
                          query={GET_COMMUNITIES}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, error }) => {
                            let defaultValue = loading
                              ? "Loading ..."
                              : error
                                ? error.message
                                : "Select...";

                            dropdownCommunities = data?.getAllCommunities || [];
                            return (
                              <Field
                                name={`Prospects[${index}].community_id`}
                                label="Community"
                                id="prospect-community"
                                isString
                                fieldsToClear={[
                                  {
                                    fieldName: `Prospects[${index}].home_sites`,
                                    value: [],
                                  },
                                  {
                                    fieldName: `Prospects[${index}].other_dropdown_values`,
                                    value: [],
                                  },
                                  {
                                    fieldName: `Prospects[${index}].csm_id`,
                                    value: null,
                                  },
                                  {
                                    fieldName: `Prospects[${index}].floor_plans`,
                                    value: [],
                                  },
                                  {
                                    fieldName: `Prospects[${index}].rank_id`,
                                    value: null,
                                  },
                                ]}
                                required={true}
                                disabled={!values.division_id}
                                component={SelectField}
                                customOnChange={(val) =>
                                  handleCommunityChange(val)
                                }
                              >
                                <option value="">{defaultValue}</option>
                                {dropdownCommunities &&
                                  dropdownCommunities.map(
                                    (community, communityIndex) => {
                                      let communityAlreadySelected = false;
                                      if (
                                        selectedCommunities.find(
                                          (selectCommunity) =>
                                            selectCommunity.value ===
                                            community.id
                                        )
                                      )
                                        communityAlreadySelected = true;

                                      return community.is_active &&
                                        values.division_id ===
                                        community.division_id ? (
                                        <option
                                          key={communityIndex}
                                          value={`${community.id}__${community.division_id}`}
                                          disabled={communityAlreadySelected}
                                          className={
                                            communityAlreadySelected
                                              ? "my-options"
                                              : ""
                                          }
                                        >
                                          {community.name}
                                        </option>
                                      ) : null;
                                    }
                                  )}
                              </Field>
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-6">
                        <DefaultQuery
                          query={GET_CSM}
                          variables={{
                            community_ids: pr.community_id
                              ? [parseInt(pr.community_id.split("__")[0])]
                              : null,
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, error }) => {
                            let defaultValue = loading
                              ? "Loading ..."
                              : error
                                ? error.message
                                : "Select...";

                            return (
                              <Field
                                name={`Prospects[${index}].csm_id`}
                                label="CSM"
                                id="csm"
                                disabled={isCSMDisabled(index)}
                                component={SelectField}
                              >
                                <option value="">{defaultValue}</option>
                                {data && isNotNULL(data.getAllCSMs)
                                  ? data.getAllCSMs.map((csm, csmIndex) => (
                                    <option key={csmIndex} value={csm.id}>
                                      {csm.first_name} {csm.last_name}
                                    </option>
                                  ))
                                  : null}
                              </Field>
                            );
                          }}
                        </DefaultQuery>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 pr-md-0">
                        <DefaultQuery
                          query={
                            pr.community_id
                              ? GET_AVAILABLE_HOMESITES
                              : GET_HOMESITES
                          }
                          variables={{
                            community_ids: pr.community_id
                              ? [parseInt(pr.community_id.split("__")[0])]
                              : null,
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data }) => {
                            let fetchType = pr.community_id
                              ? "getAvailableHome_Sites"
                              : "getAllHome_Sites";

                            return (
                              <Field
                                label="Home Sites"
                                name={`Prospects[${index}].home_sites`}
                                id="home-sites"
                                disabled={
                                  values.Prospects[index].community_id
                                    ? false
                                    : true
                                }
                                component={MultiSelectField}
                                children={data[fetchType]}
                                defaultValue={
                                  values.Prospects[index].home_sites || null
                                }
                              />
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-3 pl-md-2 pr-md-0">
                        <DefaultQuery
                          query={GET_AVAILABLE_FLOORPLANS}
                          variables={{
                            community_ids: pr.community_id
                              ? [parseInt(pr.community_id.split("__")[0])]
                              : [],
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data }) => {
                            let fetchType = "getAllFloor_Plans";
                            return (
                              <Field
                                label="Floor Plans"
                                name={`Prospects[${index}].floor_plans`}
                                id="floor-plans"
                                component={MultiSelectField}
                                children={data[fetchType]}
                                defaultValue={
                                  values.Prospects[index].floor_plans || null
                                }
                              />
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-3 pr-md-0 pl-md-2">
                        <DefaultQuery
                          query={
                            pr.community_id
                              ? GET_AVAILABLE_OTHERS
                              : GET_OTHERVALUES
                          }
                          variables={{
                            community_ids: pr.community_id
                              ? [parseInt(pr.community_id.split("__")[0])]
                              : null,
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, error }) => {
                            let fetchType = pr.community_id
                              ? "getAvailableOther_Dropdown_Values"
                              : "getAllOther_Dropdown_Values";
                            return (
                              <Field
                                disabled={
                                  values.Prospects[index].community_id
                                    ? false
                                    : true
                                }
                                label="Others"
                                name={`Prospects[${index}].other_dropdown_values`}
                                id="others"
                                component={MultiSelectField}
                                children={data[fetchType]}
                                defaultValue={
                                  values.Prospects[index]
                                    .other_dropdown_values || null
                                }
                              />
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-3 pl-md-2">
                        <Field
                          name={`Prospects[${index}].rank_id`}
                          label="Ranking"
                          id="ranking"
                          component={SelectField}
                        >
                          <option value="">Select...</option>

                          {props.getAllRankings.getAllRankings &&
                            props.getAllRankings.getAllRankings.map(
                              (ranking, index) =>
                                editForm ||
                                  convertForm ||
                                  convertFormType === "WEBFORM" ? (
                                  <option key={index} value={ranking.id}>
                                    {ranking.name}
                                  </option>
                                ) : ranking.name !== "O" ? (
                                  <option key={index} value={ranking.id}>
                                    {ranking.name}
                                  </option>
                                ) : null
                            )}
                        </Field>
                      </div>
                    </div>
                    {/* this part below is used for creating a new prospect from dashboard or a webform */}
                    {(convertForm || dashboardForm) && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              id="add_appointment"
                              label="Schedule an Appointment"
                              name={`Prospects[${index}].add_appointment`}
                              component={CheckboxField}
                              defaultValue={dashboardForm && true}
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              id="add_follow_up"
                              label="Schedule a Follow-up"
                              name={`Prospects[${index}].add_follow_up`}
                              component={CheckboxField}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-md-12">
                            {values.Prospects[index].add_appointment && (
                              <>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Title"
                                      name={`Prospects[${index}].Prospect_Appointments.title`}
                                      id="title"
                                      type="text"
                                      required
                                      component={TextField}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <Field
                                      label="Appointment Type"
                                      name={`Prospects[${index}].Prospect_Appointments.appointment_type_id`}
                                      id={`Prospects[${index}].Prospect_Appointments.appointment_type_id`}
                                      required
                                      isString
                                      component={SelectField}
                                    >
                                      <option value="">Select...</option>
                                      {props.getAllAppointmentTypes &&
                                        isNotNULL(
                                          props.getAllAppointmentTypes
                                            .getAllAppointmentTypes
                                        )
                                        ? props.getAllAppointmentTypes.getAllAppointmentTypes.map(
                                          (appointmentType) => (
                                            <optgroup
                                              label={appointmentType.name}
                                            >
                                              {constants.appointmentTypeDurations[
                                                appointmentType.name
                                              ].map((duration, index) => (
                                                <option
                                                  key={index}
                                                  value={`${appointmentType.id}__${duration}`}
                                                >
                                                  {`${duration >= 60
                                                    ? `${duration / 60
                                                    } hour${duration > 60
                                                      ? "s"
                                                      : ""
                                                    }`
                                                    : `${duration} minutes`
                                                    }`}
                                                </option>
                                              ))}
                                            </optgroup>
                                          )
                                        )
                                        : null}
                                    </Field>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="ml-3"
                                    style={{ maxWidth: "110px" }}
                                  >
                                    <Field
                                      label="Date"
                                      name={`Prospects[${index}].Prospect_Appointments.appointment_datetime`}
                                      id="appointment_datetime"
                                      minDate={new Date()}
                                      required
                                      value={
                                        values.Prospects[index]
                                          .Prospect_Appointments
                                          .appointment_datetime
                                      }
                                      component={DatePickerView}
                                    />
                                  </div>
                                </div>
                                {values.Prospects[index].community_id &&
                                  values.Prospects[index].Prospect_Appointments
                                    .appointment_type_id && (
                                    <div className="col-md-12">
                                      <DefaultQuery
                                        query={GET_CSM_AVAILABILITY_TIMESLOTS}
                                        variables={{
                                          filter: {
                                            community_id: parseInt(
                                              values.Prospects[
                                                index
                                              ].community_id.split("__")[0]
                                            ),
                                          },
                                          duration: parseInt(
                                            values.Prospects[
                                              index
                                            ].Prospect_Appointments.appointment_type_id.split(
                                              "__"
                                            )[1]
                                          ),
                                          appointment_date: formatDate(
                                            values.Prospects[index]
                                              .Prospect_Appointments
                                              .appointment_datetime
                                          ),
                                        }}
                                        fetchPolicy="network-only"
                                      >
                                        {({ data, loading, error }) => {
                                          return !error ? (
                                            <div className="backdrop-dark">
                                              <LoadingWrapper
                                                loading={loading}
                                                component={
                                                  <ScheduleChart
                                                    data={
                                                      data?.getCsmAvailabilityTimeSlots ||
                                                      []
                                                    }
                                                    loading={loading}
                                                    index={index}
                                                    value={{
                                                      startTime:
                                                        values.Prospects[index]
                                                          .Prospect_Appointments
                                                          .start_datetime,
                                                      endTime:
                                                        values.Prospects[index]
                                                          .Prospect_Appointments
                                                          .end_datetime,
                                                      csm_id:
                                                        values.Prospects[index]
                                                          .csm_id,
                                                    }}
                                                    timeFilter={timeFilterType}
                                                    setTimeFilterType={
                                                      setTimeFilterType
                                                    }
                                                    handleSelection={
                                                      handleTimeSlotSelection
                                                    }
                                                    errors={
                                                      props.errors.Prospects &&
                                                        props.errors.Prospects[
                                                        index
                                                        ]
                                                        ? props.errors
                                                          .Prospects[index]
                                                          .csm_id ||
                                                        (props.errors
                                                          .Prospects[index]
                                                          .Prospect_Appointments &&
                                                          props.errors
                                                            .Prospects[index]
                                                            .Prospect_Appointments
                                                            .start_datetime)
                                                        : null
                                                    }
                                                    communityId={parseInt(
                                                      values.Prospects[
                                                        index
                                                      ].community_id.split(
                                                        "__"
                                                      )[0]
                                                    )}
                                                  />
                                                }
                                              />
                                            </div>
                                          ) : null;
                                        }}
                                      </DefaultQuery>
                                    </div>
                                  )}
                                <div className="row">
                                  <div className="col-md-3">
                                    <Field
                                      id="send_email"
                                      label="Send Email"
                                      name={`Prospects[${index}].Prospect_Appointments.send_email`}
                                      customOnChange={(value) => {
                                        props.setFieldValue(
                                          `Prospects[${index}].Prospect_Appointments.send_email`,
                                          value
                                        );
                                      }}
                                      component={CheckboxField}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            {values.Prospects[index].add_follow_up && (
                              <>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Title"
                                      name={`Prospects[${index}].Prospect_Follow_Ups.title`}
                                      id="follow_up_title"
                                      type="text"
                                      required
                                      component={TextField}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Details"
                                      name={`Prospects[${index}].Prospect_Follow_Ups.details`}
                                      id="follow_up_details"
                                      type="text"
                                      rows={6}
                                      component={TextAreaField}
                                    />
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div
                                    className="col-md-4"
                                    style={{ maxWidth: "110px" }}
                                  >
                                    <Field
                                      label="Date"
                                      name={`Prospects[${index}].Prospect_Follow_Ups.follow_up_datetime`}
                                      id="follow_up_datetime"
                                      value={
                                        values.Prospects[index]
                                          .Prospect_Follow_Ups
                                          .follow_up_datetime
                                      }
                                      minDate={new Date()}
                                      component={DatePickerView}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              <div className="form-addCommunity mb-3">
                {editForm ? null : (
                  <CustomButton
                    color="black"
                    disabled={
                      dropdownCommunities &&
                      props.values.Prospects &&
                      props.values.Prospects.length ===
                      dropdownCommunities.length
                    }
                    className={"form_addCommunityButton"}
                    onClick={() => {
                      if (convertForm || convertFormType === "WEBFORM") {
                        prospectForm.Prospects[0].rank_id = 5;
                      }
                      arrayHelpers.push(prospectForm.Prospects[0]);
                    }}
                  >
                    Add Community
                  </CustomButton>
                )}
              </div>
            </>
          )}
        />
        {!dashboardForm && (
          <>
            <div className="row">
              <div className="col-md-6">
                <Field
                  label="Motivation/What Changed?"
                  name="others.motivation_what_changed"
                  id="others.motivation_what_changed"
                  type="text"
                  rows={5}
                  component={TextAreaField}
                />
                <div className="d-flex">
                  <Field
                    id="others.motivation_uncovered"
                    label={
                      "Motivation Uncovered: Is the life change clear and recent?"
                    }
                    name="others.motivation_uncovered"
                    component={CheckboxField}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <Field
                  label="Current Dissatisfaction"
                  name="others.current_dissatisfaction"
                  id="others.current_dissatisfaction"
                  type="text"
                  rows={5}
                  component={TextAreaField}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  label="Future Promise"
                  name="others.future_promise"
                  id="others.future_promise"
                  type="text"
                  rows={5}
                  component={TextAreaField}
                />
              </div>
              <div className="col-md-6">
                <Field
                  label="Cost + Fear/Objections"
                  name="others.cost_feat_objections"
                  id="others.cost_feat_objections"
                  type="text"
                  rows={5}
                  component={TextAreaField}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  label="Next Step"
                  name="others.next_step"
                  id="others.next_step"
                  type="text"
                  rows={5}
                  updatedAt={values?.others?.next_step_updated_at}
                  component={TextAreaField}
                />
              </div>
              <div className="col-md-6">
                <Field
                  label="Other Notes"
                  name="others.other_notes"
                  id="others.other_notes"
                  type="text"
                  rows={5}
                  updatedAt={values?.others?.other_notes_updated_at}
                  component={TextAreaField}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <Field
                  id="others.reservation"
                  label={
                    titleCustomizations && titleCustomizations[0]
                      ? titleCustomizations[0].value
                      : "Loading..."
                  }
                  name="others.reservation"
                  component={CheckboxField}
                />
              </div>
              <div className="col-md-2">
                <Field
                  id="others.video_text"
                  label={
                    titleCustomizations && titleCustomizations[1]
                      ? titleCustomizations[1].value
                      : "Loading..."
                  }
                  name="others.video_text"
                  component={CheckboxField}
                />
              </div>
            </div>
          </>
        )}
        <ErrorFocus
          names={[
            {
              fieldName: "secondary_emails_1-input",
              errorName: "hidden_secondary_emails_1",
            },
            {
              fieldName: "secondary_emails_2-input",
              errorName: "hidden_secondary_emails_2",
            },
          ]}
        />
      </div>
    </Form>
  );
};

const updateProspectSubmit = (values, props, setSubmitting) => {
  let {
    Prospects: [{ id, ...Prospect }],
    ...input
  } = values;
  input.Prospect = Prospect;
  const submitInputs = JSON.parse(JSON.stringify(input));
  const { Prospect_Appointments, add_appointment, ...rest } = input.Prospect;

  submitInputs.Prospect = rest;
  submitInputs.Prospect.community_id = parseInt(
    submitInputs.Prospect.community_id.split("__")[0]
  );

  delete submitInputs.home_sites;
  delete submitInputs.floor_plans;
  delete submitInputs.other_dropdown_values;
  props
    .updateProspect({
      variables: { input: submitInputs },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.updateProspect.code === 200) {
        const isPresentInLoggedInCommunities =
          props.getLoggedInCommunities.getLoggedInUserCommunities.find(
            (item) => item.id === submitInputs.Prospect.community_id
          );
        if (!isPresentInLoggedInCommunities && props.isDetails) {
          props.history.push("/prospect-dashboard");
        } else {
          props.onSubmit();
        }
      } else if (res.data.updateProspect.code === 409) {
        const selector = `[name="primary_email_1"]`;
        const element = document.querySelector(selector);
        element.focus();
      }
      props.setNotificationProps({
        variables: { open: true, message: res.data.updateProspect.message },
      });
    });
};

/**
 *this function creates a prospect appointment object that is passed with backend api handlers(create, update, add-duplicate) prospect
 *from webforms page and action is convert to prospect.
 * @param {object} values
 * @param {object} props
 */
const setInput = (values, props) => {
  const start_datetime = new Date(values.Prospect_Appointments.start_datetime);
  const end_datetime = new Date(values.Prospect_Appointments.end_datetime);

  values.Prospect_Appointments.start_datetime = start_datetime.toUTCString();
  values.Prospect_Appointments.end_datetime = end_datetime.toUTCString();
  values.Prospect_Appointments.csm_id = values.csm_id;

  if (values.add_follow_up) {
    const follow_up_datetime = new Date(
      values.Prospect_Follow_Ups.follow_up_datetime
    );
    values.Prospect_Follow_Ups.csm_id = values.csm_id;
    values.Prospect_Follow_Ups.follow_up_datetime =
      follow_up_datetime.toUTCString();
  } else {
    delete values.Prospect_Follow_Ups;
  }

  values.Prospect_Appointments.appointment_type_id = values
    .Prospect_Appointments.appointment_type_id
    ? parseInt(values.Prospect_Appointments.appointment_type_id.split("__")[0])
    : null;

  if (!values.add_appointment) {
    delete values.Prospect_Appointments;
  }

  delete values.add_appointment;
  delete values.add_follow_up;

  return values;
};

const createProspectSubmit = (values, props, setStatus, setSubmitting) => {
  const submitInputs = JSON.parse(JSON.stringify(values));
  submitInputs.Prospects.forEach((Prospect, index) => {
    const {
      Prospect_Appointments,
      Prospect_Follow_Ups,
      add_follow_up,
      add_appointment,
      ...rest
    } = Prospect;

    if (add_appointment || add_follow_up) {
      submitInputs.Prospects[index] = setInput(Prospect, props);
    } else {
      submitInputs.Prospects[index] = rest;
    }

    submitInputs.Prospects[index].community_id = parseInt(
      submitInputs.Prospects[index].community_id.split("__")[0]
    );
  });

  props
    .addProspect({
      variables: {
        input: submitInputs,
        registration_id: props.registration_id,
      },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.addProspect.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: { open: true, message: res.data.addProspect.message },
        });
      } else if (res.data.addProspect.code === 409) {
        setStatus({
          showAddProspectError: true,
          showAddProspectErrorMessage: res.data.addProspect.message,
          showAddProspectErrorLeadCommunities:
            res.data.addProspect.data.leadCommunities,
          showAddProspectErrorProspectCommunities:
            res.data.addProspect.data.prospectCommunities,
        });
      } else if (res.data.addProspect.code === 449) {
        setStatus({
          showArchivedProspectError: true,
          showArchivedProspectErrorMessage: res.data.addProspect.message,
        });
      }
    });
};

// this section is used only when we recover archived to prospect, its being directly called in handleSubmit
const recoverProspectSubmit = (values, props, setSubmitting) => {
  const submitInputs = JSON.parse(JSON.stringify(values));

  submitInputs.Prospects.forEach((Prospect, index) => {
    const {
      Prospect_Appointments,
      Prospect_Follow_Ups,
      add_follow_up,
      add_appointment,
      ...rest
    } = Prospect;

    if (add_appointment) {
      submitInputs.Prospects[index] = setInput(Prospect, props);
    } else {
      submitInputs.Prospects[index] = rest;
    }

    submitInputs.Prospects[index].community_id = parseInt(
      submitInputs.Prospects[index].community_id.split("__")[0]
    );
  });

  props
    .addProspectDuplicate({
      variables: {
        input: submitInputs,
        overwrite: true,
        registration_id: props.registration_id,
      },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.addProspectDuplicate.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.addProspectDuplicate.message,
          },
        });
      }
    });
};

/**
 * This function is used to format submitted data back to the form in which backend expects it in variables of
 * mutation. delete some properties, reassign string values to null etc.
 * @param {Object} submitValues
 */
const normalizeData = (submitValues) => {
  submitValues.Prospects = submitValues.Prospects.map((prospect) => {
    prospect.rank_id = prospect.rank_id || null;
    prospect.csm_id = prospect.csm_id || null;
    prospect.home_sites = prospect.home_sites || null;
    prospect.other_dropdown_values = prospect.other_dropdown_values || null;

    delete submitValues.others.next_step_updated_at;
    delete submitValues.others.other_notes_updated_at;

    prospect = {
      ...prospect,
      ...submitValues.others,
    };

    delete prospect.Prospect_Appointments.appointment_datetime;
    return prospect;
  });

  delete submitValues.others;
  delete submitValues.hidden_secondary_emails_2;
  delete submitValues.hidden_secondary_emails_1;
  delete submitValues.formatted_cell_phone_1;
  delete submitValues.formatted_cell_phone_2;
  delete submitValues.red_flag;
  delete submitValues.disable_primary_email_1;
  delete submitValues.disable_primary_email_2;

  if (!submitValues.realtor_id) {
    submitValues.realtor_id = null;
  }
  if (submitValues.primary_email_1 === "") {
    submitValues.primary_email_1 = null;
  }
  if (submitValues.cell_phone_1 === "") {
    submitValues.cell_phone_1 = null;
  }
  if (submitValues.primary_email_2 === "") {
    submitValues.primary_email_2 = null;
  }
  if (submitValues.cell_phone_2 === "") {
    submitValues.cell_phone_2 = null;
  }

  return submitValues;
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    if (props.editForm) {
      const { cell_phone_1, cell_phone_2 } = props.data;
      const formatted_cell_phone_1 = phoneDisplay(cell_phone_1);
      const formatted_cell_phone_2 = phoneDisplay(cell_phone_2);
      return { formatted_cell_phone_1, formatted_cell_phone_2, ...props.data };
    } else if (props.convertForm || props.recoverForm) {
      return { ...props.data };
    }
    return { ...prospectForm };
  },
  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    let submitValues = Object.assign({}, values);
    /* Normalize input state according to mutation input format */
    submitValues = normalizeData(submitValues);

    props.recoverForm
      ? recoverProspectSubmit(submitValues, props, setSubmitting)
      : props.editForm
        ? updateProspectSubmit(submitValues, props, setSubmitting)
        : createProspectSubmit(submitValues, props, setStatus, setSubmitting);
  },
  validationSchema: addProspectSchema,
  displayName: "new-prospect-form",
})(ProspectForm);
