import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Dropdown, Col, DropdownButton, Form } from "react-bootstrap";
import { FaBullhorn, FaFilter, FaPen, FaTrashAlt, FaUser } from "react-icons/fa";

import reducer, { getInitialState } from "./prospect-reducer";
import { Auth } from "../../../rbac/rbac";
import { GET_CSM } from "../../../graphql/queries";
import { DefaultQuery } from "../../../graphql/default-query";
import { DashboardContext } from "../../../context";
import routeConstants from "../../layout/routes";
import Dialog from "../../../components/dialog/dialog";
import ListProspectsFilters from "../details/prospect-list-filters";
import ProspectsListMassUpdate from "../details/prospect-list-mass-update";
import EditProspectView from "./edit-prospect";
import DeleteDialogBox from "../../../components/dialog/delete-dialog-box";
import PermanentDeleteConfirmation from "../../../components/dialog/permanent-delete";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import TableView, {
  SortableHeader,
  TextHeader,
  DetailLinkCell,
  TextCell,
  FlagCell,
  TextCellWithMapper,
  ListCell,
  CheckboxCell,
  TooltipCell,
  ActionCell,
  PhoneCell,
  includes,
  stringComparator,
  boolText,
  dateComparator,
  EmailCell,
  buyerTwoIconCellObject,
} from "../../../components/new-table";
import {
  formatActivityDate,
  getCardParams,
  getSubscribedEmails,
  getUpdatedParams,
  phoneDisplay,
  isNotNULL,
  isBuyerTwo,
  getAllEmails
} from "../../../utils/helpers";
import { MARKETING_ONLY, LEAD } from "../../../utils/constants";
import { ProspectConvertFailedDialog } from "../details/prospect-convert-failed-dialog";

let csmTitle = "";

/** @module ProspectsTableModule */

/**
 * This component is a table used to display all the prospects. It contains pagination, searchbar, filtering,
 * mass-update and export to csv options. It has multiple hover-actions. It shows multiple columns displaying basic
 * information related to prospect. Table uses reducer to maintain its state. It also accesses store to check if
 * table parameters are already stored or not.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of prospects to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const ProspectsTable = ({
  customizationText,
  data,
  selectedCommunity,
  selectedDivision,
  getTableParams: { tableParams },
  ...props
}) => {
  const { currentCard } = useContext(DashboardContext);
  const { is_manager, is_divmgr } = useContext(Auth);
  const cacheParams = getCardParams(currentCard.tableTitle, tableParams);

  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ currentCard, loading: data.loading, cache: cacheParams })
  );

  const {
    render,
    userPageCount,
    pageSize,
    Prospects,
    showDeleteConfirmation,
    showPermanentDeletConfirmation,
    isPermDeleteSubmitted,
    editItemId,
    deleteProspectIds,
    convertProspectIds,
    deleteCustomerId,
    showConvertToLeadConfirmation,
    showConvertToMarketingOnlyConfirmation,
    loading,
    showCsmDialog,
    csmId,
    disableChange,
    selectedRowData,
    showConfirmation,
  } = state;

  const [convertMessage, setConvertMessage] = useState("")
  const [showMultipleConvertDialog, setShowMultipleConvertDialog] = useState(false);
  const [convertMultipleSuccessData, setConvertMultipleSuccessData] = useState([])
  const [convertMultipleFailureData, setConvertMultipleFailureData] = useState([])
  const [convertMultipleCommunities, setConvertMultipleCommunities] = useState([])
  const [showLeadConvertConfirmation, setShowLeadConvertConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [showConvertDialog, setShowConvertDialog] = useState(false)
  const [
    showMultipleConvertDeleteConfirmDialog,
    setShowMultipleConvertDeleteConfirmDialog
  ] = useState(false)
  const [showEdit, setShowEdit] = useState(false);
  const [note, setNote] = useState("");
  const [multipleConvertType, setMultipleConvertType] = useState('')

  useEffect(() => {
    if (!showMultipleConvertDialog) {
      setConvertMultipleFailureData([])
      setConvertMultipleSuccessData([])
      setConvertMultipleCommunities([])
    }
  }, [showMultipleConvertDialog])

  useEffect(() => {
    if (deleteProspectIds && (!showMultipleConvertDeleteConfirmDialog && !showConvertDialog && showMultipleConvertDialog))
      handleDeleteConfirmation()
  }, [deleteProspectIds, convertMultipleFailureData])

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getProspects && data.getProspects.Prospects) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          Prospects: data.getProspects.Prospects,
          loading: false,
          userPageCount: Math.ceil(data.getProspects.total_count / pageSize),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, render: false, pageSize: cacheParams.limit },
      });
    }
    props.setPreviousCard({ variables: { title: currentCard.title } });
  }, [props.currentCard.title]);

  /** Refetch table data on any update like create, edit, delete */
  useEffect(() => {
    if (props.updated !== null) {
      data.refetch();
    }
  }, [props.updated]);

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };


  const handleCancelConvert = () => {
    setShowMultipleConvertDialog(false)
    setDeleteIndex(null)
    dispatch({
      type: "UPDATE",
      payload: {
        deleteProspectIds: null,
      },
    });

  };
  const handleCancelMultipleConvertDeleteConfirmation = () => {
    setShowMultipleConvertDeleteConfirmDialog(false)
    setDeleteIndex(null)
    dispatch({
      type: "UPDATE",
      payload: {
        deleteProspectIds: null,
      },
    });

  };

  const handleChange = (value) => {
    setNote(value)
  };


  const handleMultipleConvertDeleteConfirmation = (index, itemID) => {
    !convertProspectIds && setShowMultipleConvertDialog(false)
    setShowMultipleConvertDeleteConfirmDialog(true)
    setDeleteIndex(index)
    dispatch({
      type: "UPDATE",
      payload: {
        deleteProspectIds: itemID,
      },
    });
    setShowConvertDialog(false)
    setConvertMessage("")
  };

  const handleMultipleConvertDelete = () => {
    if (convertMultipleFailureData.length > 1) {
      setShowMultipleConvertDeleteConfirmDialog(false)
      setConvertMultipleFailureData(convertMultipleFailureData.filter((_, idx) => idx !== deleteIndex))
    } else {
      setShowMultipleConvertDeleteConfirmDialog(false)
      setShowMultipleConvertDialog(false)
      setConvertMultipleFailureData(convertMultipleFailureData.filter((_, idx) => idx !== deleteIndex))
      setDeleteIndex(null)

    }
  };
  /**
   * It is used to set delete id and show hide the dialog box.
   * @param {Number} itemId id of prospect to be deleted
   * @param {Number} customer_id id of the prospect
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleDelete = (itemId, customer_id) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: true,
        deleteProspectIds: itemId,
        deleteCustomerId: customer_id,
      },
    });
  };

  /**
   * It is used to set edit id
   * @param {Number} itemId id of prospect to be deleted
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleEdit = (itemId) => {
    dispatch({ type: "UPDATE", payload: { editItemId: itemId } });
    setShowEdit(true);
  };

  /**
   * It is used to render the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const deleteConfirmationBody = () => {
    return <DeleteDialogBox setFunc={setNote} customer />;
  };

  /**
   * this function is used when delete has been confirmed
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   */
  const handleDeleteConfirmation = () => {
    dispatch({ type: "UPDATE", payload: { showDeleteConfirmation: false } });

    props.addProspectDeleteRequests({
      variables: { input: { prospect_ids: deleteProspectIds, note } },
    }).then((res) => {
      if (res.data.addProspectDeleteRequests.code === 200) {
        dispatch({
          type: "UPDATE",
          payload: {
            showConvertDialog: false,
            convertMessage: "",
            convertMultipleFailureData: [],
          },
        });
        setNote("");
        props.update();
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.addProspectDeleteRequests.message,
          },
        });
      }
    })
      .catch((res) => {
        res.graphQLErrors.map((error) => {
          return error.message;
        });
      });
  };

  /**
   * It is used to hide the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleCancelConvertConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        showConvertToLeadConfirmation: false,
        showConvertToMarketingOnlyConfirmation: false,
        convertProspectIds: []
      },
    });
  };

  /**
   * It is used to set convert id and show the convert confirmation dialog box.
   * @param {Number} itemId id of prospect to be deleted
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleConvertToLead = (itemId) => {
    dispatch({
      type: "UPDATE",
      payload: { showConvertToLeadConfirmation: true, convertProspectIds: itemId },
    });
  };

  /**
 * It is used to set convert id and show the convert confirmation dialog box.
 * @param {Number} itemId id of prospect to be deleted
 * @function
 * @inner
 * @memberof module:ProspectsTableModule
 * @see {@link module:ProspectsTableModule~ProspectsTable}
 */
  const handleConvertToMarketingOnly = (itemId) => {
    dispatch({
      type: "UPDATE",
      payload: { showConvertToMarketingOnlyConfirmation: true, convertProspectIds: itemId },
    });
  };

  /**
   * this function is used when convert to lead has been confirmed
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   */
  const handleConvertToLeadConfirmation = () => {
    props.convertProspectToLead({
      variables: { input: { prospect_ids: [convertProspectIds] } },
    }).then((res) => {

      if (res.data.convertProspectToLead.code === 200) {
        dispatch({
          type: "UPDATE",
          payload: { showConvertToLeadConfirmation: false, convertProspectIds: [] },
        });
        setShowConvertDialog(false)
        setConvertMessage("")
        setShowMultipleConvertDialog(false)
        setDeleteIndex(null)
        props.update(true);
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertProspectToLead.message,
          },
        });
      } else if (res.data.convertProspectToLead.code === 207) {
        setConvertMultipleFailureData(res.data.convertProspectToLead.data.failureData)
        setConvertMultipleSuccessData(res.data.convertProspectToLead.data.successData)
        setConvertMultipleCommunities(res.data.convertProspectToLead.data?.communities)
        setShowMultipleConvertDialog(true)
        setMultipleConvertType(LEAD)
        if (res.data.convertProspectToLead.data.failureData.length === 1) {
          dispatch({
            type: "UPDATE",
            payload: {
              deleteProspectIds: [res.data.convertProspectToLead.data.failureData[0].id],
              showConvertDialog: true,
              convertMessage:
                res.data.convertProspectToLead.data.failureData[0]
                  .first_name_1 +
                " " +
                res.data.convertProspectToLead.data.failureData[0]
                  .last_name_1 +
                " " +
                res.data.convertProspectToLead.message,
            },
          });

          dispatch({
            type: "UPDATE",
            payload: {
              convertMultipleFailureData:
                res.data.convertProspectToLead.data.failureData,
            },
          });
        }
      } else if (res.data.convertProspectToLead.code === 409) {
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertProspectToLead.message,
          },
        });
      }
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error;
      });
    });
  };

  /**
   * this function is used when convert to lead has been confirmed
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   */
  const handleConvertToMarketingOnlyConfirmation = () => {
    props.convertProspectToMarketingOnly({
      variables: { input: { prospect_ids: [convertProspectIds] } },
    }).then((res) => {
      if (res.data.convertProspectToMarketingOnly.code === 200) {
        dispatch({
          type: "UPDATE",
          payload: { showConvertToMarketingOnlyConfirmation: false, convertProspectIds: [] },
        });
        setShowMultipleConvertDialog(false)
        props.update(true);
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertProspectToMarketingOnly.message,
          },
        });
      } else if (res.data.convertProspectToMarketingOnly.code === 207) {
        setConvertMultipleFailureData(res.data.convertProspectToMarketingOnly.data.failureData)
        setConvertMultipleSuccessData(res.data.convertProspectToMarketingOnly.data.successData)
        setConvertMultipleCommunities(res.data.convertProspectToMarketingOnly.data?.communities)
        setShowMultipleConvertDialog(true)
        setMultipleConvertType(MARKETING_ONLY)
        if (res.data.convertProspectToMarketingOnly.data.failureData.length === 1) {
          dispatch({
            type: "UPDATE",
            payload: {
              deleteProspectIds: [res.data.convertProspectToMarketingOnly.data.failureData[0].id],
              showConvertDialog: true,
              convertMessage:
                res.data.convertProspectToMarketingOnly.data.failureData[0]
                  .first_name_1 +
                " " +
                res.data.convertProspectToMarketingOnly.data.failureData[0]
                  .last_name_1 +
                " " +
                res.data.convertProspectToMarketingOnly.message,
            },
          });

          dispatch({
            type: "UPDATE",
            payload: {
              convertMultipleFailureData:
                res.data.convertProspectToMarketingOnly.data.failureData,
            },
          });
        }
      } else if (res.data.convertProspectToMarketingOnly.code === 409) {
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertProspectToMarketingOnly.message,
          },
        });
      }
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error;
      });
    });
  };

  /**
   * It is used to in the convert dialog box to open delete confirmation dialog once delete button is pressed.
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleConvertDeleteDialog = () => {
    handleDeleteConfirmation();
  };
  const onSaveFilter = (message) => {
    props.setNotificationProps({ variables: { open: true, message: message } });
  };
  const ehancedFilter = (onSaveFilter, currentCard, ...rest) => (Component) => {
    const WrappedComponent = (props) => {
      return <Component {...props} onSaveFilter={onSaveFilter} currentCard={currentCard} {...rest} />;
    };
    return WrappedComponent;
  };

  /**
   * It is used to show hide the change csm dialog box.
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleShowCsm = () => {
    csmTitle = null;
    dispatch({
      type: "UPDATE",
      payload: {
        csmId: null,
        disableChange: true,
        showCsmDialog: !showCsmDialog,
      },
    });
  };

  /**
   * It is used to render the body the change csm dialog
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   * @returns {JSX.Element}
   */
  const handleCsm = () => {
    return (
      <DefaultQuery
        query={GET_CSM}
        variables={{
          community_ids: selectedRowData.community && [
            selectedRowData.community.id,
          ],
        }}
      >
        {({ data, loading, error }) => {
          let defaultValue = loading
            ? "Loading ..."
            : error
              ? error.message
              : null;
          return (
            <Form.Group as={Col} id="filterCSM">
              <DropdownButton
                alignRight
                drop="down"
                variant="secondary"
                id="filter-item-btn"
                key="down"
                title={csmTitle ? csmTitle : "Select...  "}
                className="filter-item"
              >
                {defaultValue && <Dropdown.Item>{defaultValue}</Dropdown.Item>}
                {isNotNULL(data.getAllCSMs) &&
                  data.getAllCSMs.map((item) => {
                    return (
                      <Dropdown.Item
                        onSelect={() => handleCsmChange(item)}
                        key={item.id}
                        eventKey={item.id}
                      >
                        {item.first_name} {item.last_name}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
            </Form.Group>
          );
        }}
      </DefaultQuery>
    );
  };

  /**
   * It is used to set the csmId  and enable the change button
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleCsmChange = (val) => {
    csmTitle = val.first_name + " " + val.last_name;
    dispatch({
      type: "UPDATE",
      payload: { csmId: val.id, disableChange: false },
    });
  };

  /**
   * It is used to hide and show change csm confirmation dialog
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const handleChangeConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { showConfirmation: !showConfirmation },
    });
  };

  /**
   * this function is used when change csm has been confirmed
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   */
  const handleCsmConfirmation = () => {
    let transferProspectInput = {
      csm_id: csmId,
      prospect_ids: selectedRowData.id,
    };
    dispatch({
      type: "UPDATE",
      payload: {
        showConfirmation: false,
        showCsmDialog: false,
        disableChange: false,
      },
    });
    csmTitle = null;
    props
      .changeCSM({
        variables: { input: transferProspectInput },
      })
      .then((res) => {
        if (res.data.transferProspect.code === 200) {
          dispatch({ type: "UPDATE", payload: { loading: true } });
          data.refetch();
          props.setNotificationProps({
            variables: {
              open: true,
              message: res.data.transferProspect.message,
            },
          });
        }
      });
  };

  /**
   * thi function is used to change the ispermDeleteSubmitted.
   */
  const handlePermanentDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { isPermDeleteSubmitted: !isPermDeleteSubmitted },
    });
  };

  /**
   * It is used to when permanent delete has successfully done and it is used to show the success notification and refetch data and hides the permanent delete confirmation dialog.
   * @param {string} message message to be shown in notification bar
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const onPermanentDeletionSuccess = (message) => {
    dispatch({
      type: "UPDATE",
      payload: { loading: true, showPermanentDeletConfirmation: false },
    });
    props.update();
    data.refetch();
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
  };

  /**
   * It is used to render the permanent delete confirmation dialog.
   * @param {number} deletCustomerID customer id
   * @param {boolean} isPermDeleteSubmitted boolean that shows if permanent delete is submitted
   * @param {funciton} onPermanentDeletionSuccess its a function called and permanent delete is successfully performed
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const reConfirmationBody = (
    deleteCustomerId,
    isPermDeleteSubmitted,
    onPermanentDeletionSuccess
  ) => {
    return (
      deleteCustomerId && (
        <PermanentDeleteConfirmation
          customerId={deleteCustomerId}
          isSubmitted={isPermDeleteSubmitted}
          onSubmit={onPermanentDeletionSuccess}
        />
      )
    );
  };

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:ProspectsTableModule
   * @see {@link module:ProspectsTableModule~ProspectsTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }
    const columnItem = columns.find(
      (item) => item.accessor === tableProps.sortColumn.name
    );

    const pageCount = Math.ceil(
      data.getProspects.total_count / tableProps.pageSize
    );

    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: {
        id: tableProps.sortColumn.id,
        name: (columnItem && columnItem.sortName) || tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    if (pageCount !== userPageCount)
      callDispatch("UPDATE", {
        userPageCount: pageCount,
      });

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.tableTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   *  This defines columns of the table. It has multiple columns, displaying information of the prospect. Most of
   * the columns are sortable. Clicking the first name column opens the details page of prospect. Buyer two icon 
   * shows up as the first column if the prospect has buyer 2 data as well.

   * @constant
   * @memberof module:ProspectsTableModule
   */
  const columns = React.useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "red_flag",
        component: TextHeader,
        csvHeader: "Red Flagged",
        sortable: false,
        csvMapper: (field, accessor) => (field[accessor] ? true : false),
        filter: boolText,
        cell: {
          component: FlagCell,
        },
      },
      {
        id: "1",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: buyerTwoIconCellObject,
      },
      {
        id: "2",
        header: "First Name",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => routeConstants.PROSPECT_DETAIL,
        },
      },
      {
        id: "3",
        header: "Last Name",
        accessor: "last_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: TextCell,
        },
      },
      {
        id: "4",
        header: "Primary Email",
        accessor: "primary_email_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: EmailCell,
          check: "primary_email_1_status",
          callback: getAllEmails
        },
      },
      {
        id: "5",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "6",
        header: "Next Step",
        accessor: "next_step",
        component: TextHeader,
        sortable: false,
        cell: {
          component: TooltipCell,
        },
      },
      {
        id: "7",
        header: "Rank",
        accessor: "rank",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        sortable: false,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] ? field[accessor].name : "",
        },
      },
      {
        id: "8",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        sortable: false,
        filter: includes,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "9",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "10",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "11",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "12",
        header: "Mailchimp 2 Subscription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field) ? field[accessor] ? "Subscribed" : "Unsubscribed" : "",
        cell: { component: null },
      },
      {
        id: "13",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        //TODO: need to change community displayed as done in handle commuities
        id: "14",
        header: "Community(ies)",
        accessor: "all_communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].map(({ name }) => name) : "",
        cell: {
          component: ListCell,
        },
      },
      {
        id: "15",
        header: "CSM Name",
        accessor: "csm",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name + " " + field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name,
        },
      },
      {
        id: "16",
        header: "Realtor",
        accessor: "realtor",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name +
            " " +
            (field[accessor].last_name ? field[accessor].last_name : "")
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name +
            " " +
            (field[accessor].last_name ? field[accessor].last_name : ""),
        },
      },
      {
        id: "17",
        header: " Prospect Source",
        accessor: "customer_origination_area.name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: TextCell,
          mapper: (field, accessor) => field?.customer_origination_area?.name || "—"
        },
      },
      {
        id: "18",
        header: "Last Activity",
        accessor: "last_activity",
        component: TextHeader,
        sortable: false,
        cell: {
          component: TooltipCell,
        },
      },
      {
        id: "19",
        header: "Last Activity Date",
        accessor: "last_activity_datetime",
        component: SortableHeader,
        comparator: dateComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
      {
        id: "20",
        header: "Date Created",
        accessor: "created_at",
        component: SortableHeader,
        comparator: dateComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
      {
        id: "21",
        header: customizationText.titleCustomizations[0].value,
        accessor: "reservation",
        component: TextHeader,
        sortable: false,
        filter: boolText,
        cell: {
          component: CheckboxCell,
        },
      },
      {
        id: "22",
        header: "Motivation",
        accessor: "motivation_uncovered",
        component: TextHeader,
        sortable: false,
        filter: boolText,
        cell: {
          component: CheckboxCell,
        },
      },
      {
        id: "23",
        header: customizationText.titleCustomizations[1].value,
        accessor: "video_text",
        component: TextHeader,
        sortable: false,
        filter: boolText,
        cell: {
          component: CheckboxCell,
        },
      },
    ],
    [currentCard.filter]
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:ProspectsTableModule
   */
  const hoverActions = React.useMemo(
    () => [
      {
        tooltip: "Convert to Marketing Only",
        icon: FaBullhorn,
        component: ActionCell,
        action: ({ id, ...row }) => handleConvertToMarketingOnly(id)
      },
      {
        tooltip: "Convert to Lead",
        icon: FaFilter,
        component: ActionCell,
        action: ({ id, ...row }) => handleConvertToLead(id)
      },
      {
        tooltip: "Change CSM",
        icon: FaUser,
        component: ActionCell,
        action: (row) => {
          dispatch({
            type: "UPDATE",
            payload: { selectedRowData: { ...row } },
          });
          handleShowCsm();
        },
      },
      {
        tooltip: "Edit",
        icon: FaPen,
        component: ActionCell,
        action: ({ id, ...row }) => {
          handleEdit(id);
        },
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id, customer_id, ...row }) => {
          handleDelete(id, customer_id);
        },
      },
    ],
    []
  );

  /**
   * This is an object that defines table title, and massActions and filters for the table.
   *
   * @constant
   * @memberof module:ProspectsTableModule
   */
  const tableOptions = {
    title: currentCard.tableTitle,
    massActions: {
      render: (getSelectedRows) => {
        let id = getSelectedRows().map((x) => x.id);
        let emails = getSubscribedEmails(getSelectedRows());
        return (
          <ProspectsListMassUpdate
            selectedCommunity={props.selectedCommunity}
            id={id}
            emails={emails}
            disable={false}
            submitHandler={props.update}
            clearState={() => { }}
            data={data}
          />
        );
      },
    },
    FilterItems: ehancedFilter(onSaveFilter, currentCard)(ListProspectsFilters),
  };

  return (
    <div>
      <Dialog
        show={showCsmDialog}
        onHide={handleShowCsm}
        disabled={disableChange}
        size="sm"
        title="Change CSM"
        body={handleCsm()}
        click={handleChangeConfirmation}
        clickname="CHANGE"
        closename="CANCEL"
      />
      <Dialog
        title="Confirmation"
        show={showConfirmation}
        onHide={handleChangeConfirmation}
        body="Are you sure you want to change the CSM?"
        click={handleCsmConfirmation}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showConvertToMarketingOnlyConfirmation}
        onHide={handleCancelConvertConfirmation}
        title="Prospect to Marketing Only Confirmaton"
        body="Are you sure you want to convert this Prospect to Marketing Only?"
        click={handleConvertToMarketingOnlyConfirmation}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showConvertToLeadConfirmation}
        onHide={handleCancelConvertConfirmation}
        title="Prospect to Lead Confirmaton"
        body="Are you sure you want to convert this Prospect to Lead?"
        click={handleConvertToLeadConfirmation}
        clickname="YES"
        closename="NO"
      />
      <ProspectConvertFailedDialog
        show={showMultipleConvertDialog}
        onHide={handleCancelConvert}
        successData={convertMultipleSuccessData}
        failureData={convertMultipleFailureData}
        communities={convertMultipleCommunities}
        currentCommunity={Prospects.find(prospect => prospect.id === convertProspectIds)?.community}
        handleDelete={handleMultipleConvertDeleteConfirmation}
      />

      <Dialog
        show={showMultipleConvertDeleteConfirmDialog}
        onHide={handleCancelMultipleConvertDeleteConfirmation
        }
        title="Delete Confirmation"
        body={<DeleteDialogBox setFunc={handleChange} massDelete={true} />}
        click={handleMultipleConvertDelete}
        clickname="YES"
        closename="NO"
      />

      <Dialog
        show={showDeleteConfirmation}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showDeleteConfirmation: false },
          })
        }
        title="Delete Confirmation"
        body={deleteConfirmationBody()}
        click={handleDeleteConfirmation}
        isPermanentDelete={is_manager() || is_divmgr()}
        permanentDeleteClick={() =>
          dispatch({
            type: "UPDATE",
            payload: {
              showDeleteConfirmation: false,
              showPermanentDeletConfirmation: true,
            },
          })
        }
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showPermanentDeletConfirmation}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showPermanentDeletConfirmation: false },
          })
        }
        title="Re-Confirmation"
        body={reConfirmationBody(
          deleteCustomerId,
          isPermDeleteSubmitted,
          onPermanentDeletionSuccess
        )}
        click={handlePermanentDeleteConfirmation}
        clickname="Permanently Delete"
        closename="NO"
      />

      <FadeIn show={render}>
        <div className="table-prospects">
          <TableView
            columns={columns}
            data={Prospects}
            hoverActions={hoverActions}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getProspects
                ? data.getProspects.total_count
                : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            searchTerm={state.search}
            sortColumn={state.order}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}

      {showEdit && (
        <EditProspectView
          id={editItemId}
          submitHandler={() => {
            setShowEdit(false);
            props.update(true);
          }}
          setShowEdit={setShowEdit}
          close={() => setShowEdit(false)}
        />
      )}
    </div>
  );
};

export { ProspectsTable as default };
