import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";
import * as validations from "../../../utils/common-validations";
import {
  checkIsBeforeTime,
  checkIsBeforeCurrentTime,
} from "../../../utils/helpers";

export const prospectForm = {
  first_name_1: "",
  last_name_1: "",
  primary_email_1: null,
  cell_phone_1: null,
  formatted_cell_phone_1: "",
  secondary_emails_1: [],
  hidden_secondary_emails_1: "",
  first_name_2: "",
  last_name_2: "",
  primary_email_2: null,
  cell_phone_2: null,
  formatted_cell_phone_2: "",
  secondary_emails_2: [],
  hidden_secondary_emails_2: "",
  city: "",
  state_id: undefined,
  realtor_id: undefined,
  zip: "",
  street_address: "",
  customer_origination_area_id: undefined,
  division_id: null,
  osc_notes: "",
  Prospects: [
    {
      community_id: "",
      rank_id: "",
      csm_id: "",
      floor_plans: [],
      home_sites: [],
      other_dropdown_values: [],
      add_appointment: false,
      Prospect_Appointments: {
        send_email: true,
        title: "",
        details: "",
        start_datetime: new Date(),
        end_datetime: new Date(),
        appointment_datetime: new Date(),
        appointment_type_id: null,
        csm_id: undefined,
      },
      Prospect_Follow_Ups: {
        title: "",
        details: "",
        follow_up_datetime: new Date(),
        csm_id: undefined
      }
    },
  ],
  others: {
    next_step: "",
    other_notes: "",
    future_promise: "",
    motivation_what_changed: "",
    motivation_uncovered: false,
    cost_feat_objections: "",
    current_dissatisfaction: "",
    reservation: false,
    video_text: false,
  },
};

Yup.addMethod(Yup.mixed, "stringInArray", function (ref, message) {
  const msg = message;
  return this.test("equalTo", msg, function (value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || refValue.indexOf(value) === -1;
  });
});

Yup.addMethod(Yup.mixed, "compareStrings", function (ref, message) {
  const msg = message;
  return this.test("equalTo", msg, function (value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || refValue !== value;
  });
});

Yup.addMethod(Yup.mixed, "validateCell", function (ref, message) {
  const msg = message;
  return this.test("equalTo", msg, function (value) {
    let refValue = this.resolve(ref);
    return (
      (refValue && /^[0-9]{10}$/.test(refValue)) ||
      refValue === null ||
      refValue === undefined ||
      refValue === ""
    );
  });
});

Yup.addMethod(Yup.mixed, "validateTime", function (ref, message) {
  const msg = message;
  return this.test("validateTime", msg, function (value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || checkIsBeforeTime(refValue, value);
  });
});

Yup.addMethod(Yup.mixed, "validateStartTime", function (ref, message, refdate) {
  const msg = message;
  return this.test("validateStartTime", msg, function (value) {
    let refValue = this.resolve(ref);
    let refDateValue = this.resolve(refdate);
    return !checkIsBeforeCurrentTime(refValue, refDateValue);
  });
});

export const addProspectSchema = Yup.object().shape(
  {
    first_name_1: validations.name.required(errorMessages.REQUIRE_FIELD),
    last_name_1: validations.name.required(errorMessages.REQUIRE_FIELD),
    primary_email_1: Yup.string().when(["formatted_cell_phone_1"], {
      is: (formatted_cell_phone_1) => !formatted_cell_phone_1,
      then: validations.email
        .required(errorMessages.REQUIRE_FIELD)
        .stringInArray(
          Yup.ref("secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(
          Yup.ref("hidden_secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(
          Yup.ref("primary_email_2"),
          errorMessages.PRIMARY_MATCH
        ),
      otherwise: validations.email
        .stringInArray(
          Yup.ref("secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(
          Yup.ref("hidden_secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(Yup.ref("primary_email_2"), errorMessages.PRIMARY_MATCH)
        .nullable(),
    }),
    formatted_cell_phone_1: Yup.string().when(["primary_email_1"], {
      is: (primary_email_1) => !primary_email_1,
      then: Yup.string()
        .nullable()
        .validateCell(Yup.ref("cell_phone_1"), errorMessages.CELL_LENGTH)
        .required(errorMessages.REQUIRE_FIELD),
      otherwise: Yup.string()
        .nullable()
        .validateCell(Yup.ref("cell_phone_1"), errorMessages.CELL_LENGTH),
    }),
    hidden_secondary_emails_1: validations.email
      .compareStrings(Yup.ref("primary_email_1"), errorMessages.EMAIL_MATCHED)
      .stringInArray(Yup.ref("secondary_emails_1"), errorMessages.EMAIL_EXISTS),
    secondary_emails_1: Yup.array(),

    first_name_2: validations.name,
    last_name_2: validations.name,
    primary_email_2: validations.email
      .stringInArray(Yup.ref("secondary_emails_2"), errorMessages.EMAIL_MATCHED)
      .compareStrings(
        Yup.ref("hidden_secondary_emails_2"),
        errorMessages.EMAIL_MATCHED
      )
      .compareStrings(Yup.ref("primary_email_1"), errorMessages.PRIMARY_MATCH)
      .nullable(),
    formatted_cell_phone_2: Yup.string()
      .nullable()
      .validateCell(Yup.ref("cell_phone_2"), errorMessages.CELL_LENGTH),
    hidden_secondary_emails_2: validations.email
      .compareStrings(Yup.ref("primary_email_2"), errorMessages.EMAIL_MATCHED)
      .stringInArray(Yup.ref("secondary_emails_2"), errorMessages.EMAIL_EXISTS),
    secondary_emails_2: Yup.array(),

    street_address: validations.isSpace
      .max(128, errorMessages.ADDRESS_LONG)
      .nullable(),
    city: validations.isSpace.max(24, errorMessages.CITY_LONG).nullable(),
    state_id: Yup.string().default("Select...").nullable(),
    zip: validations.isSpace.max(10, errorMessages.ZIP_LONG).nullable(),
    realtor_id: Yup.string().default("Select...").nullable(),
    customer_origination_area_id: Yup.string().required(errorMessages.REQUIRE_FIELD),
    division_id: Yup.string().required(errorMessages.REQUIRE_FIELD).nullable(),
    osc_notes: validations.isSpace.nullable(),

    Prospects: Yup.array().of(
      Yup.object().shape({
        community_id: Yup.string()
          .default("Select...")
          .required(errorMessages.REQUIRE_FIELD)
          .nullable(),
        csm_id: Yup.string().when(["add_appointment", "add_follow_up", "Prospect_Appointments"], {
          is: (add_appointment, add_follow_up) => add_appointment === true && add_follow_up === true,
          then: Yup.string()
            .required(errorMessages.REQUIRE_FIELD)
            .default("Select...")
            .nullable(),
          otherwise: Yup.string().default("Select...").nullable(),
        }),
        floor_plans: Yup.array().nullable(),
        rank_id: Yup.string().default("Select...").nullable(),
        home_sites: Yup.array().nullable(),
        other_dropdown_values: Yup.array().nullable(),
        Prospect_Appointments: Yup.object().when("add_appointment", {
          is: (add_appointment, index) => add_appointment === true,
          then: Yup.object().shape({
            appointment_type_id: Yup.string()
              .default("Select...")
              .required(errorMessages.REQUIRE_FIELD)
              .nullable(),
            title: validations.isSpace
              .required(errorMessages.REQUIRE_FIELD)
              .max(64, errorMessages.TITLE_LONG)
              .default("")
              .nullable(),
            details: Yup.string()
              .max(256, "Details must not be more than 256 characters")
              .default("")
              .nullable(),
            appointment_datetime: Yup.date().required().default(new Date()),
            start_datetime: Yup.string()
              .required()
              .validateStartTime(
                Yup.ref("start_datetime"),
                errorMessages.START_TIME,
                Yup.ref("appointment_datetime")
              )
          }),
          otherwise: Yup.object(),
        }),
        Prospect_Follow_Ups: Yup.object().when("add_follow_up", {
          is: (add_follow_up, index) => add_follow_up === true,
          then: Yup.object().shape({
            title: validations.isSpace
              .required(errorMessages.REQUIRE_FIELD)
              .max(64, errorMessages.TITLE_LONG)
              .default("")
              .nullable(),
            details: Yup.string()
              .max(256, "Details must not be more than 256 characters")
              .default("")
              .nullable(),
            follow_up_datetime: Yup.date().required().default(new Date()),
          })
        })
      })
    ),
    others: Yup.object().shape({
      next_step: validations.isSpace.nullable(),
      other_notes: validations.isSpace.nullable(),
      motivation_what_changed: validations.isSpace.nullable(),
      current_dissatisfaction: validations.isSpace.nullable(),
      future_promise: validations.isSpace.nullable(),
      cost_feat_objections: validations.isSpace.nullable(),
    }),
  },
  ["primary_email_1", "formatted_cell_phone_1"]
);
