import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { compose, graphql } from "react-apollo";
import { Form, Field, withFormik } from "formik";
import { CREATE_PROSPECT_FILTER } from "../../../graphql/mutations";
import { DefaultQuery } from "../../../graphql/default-query";
import { GET_TITLE_CUSTOMIZATIONS } from "../../../cacheql/queries";
import { FaFlag } from "react-icons/fa";
import GET_SELECTED_COMMUNITY from "../../../cacheql/queries/community";
import MultiSelectField from "../../../components/fields/multi-select-field";
import DatePickerView from "../../../components/fields/date-picker";
import moment from "moment";
import withUserContext from "../../../hoc/with-user-context";
import SelectField from "../../../components/fields/select-field";
import TextField from "../../../components/fields/text-field";
import CheckboxField from "../../../components/fields/checkbox-field";
import CustomButton from "../../../components/custom-button";
import ErrorFocus from "../../../components/error-focus/error-focus";
import * as cardsType from "../../lead/dashboard/lead-card-types";
import {
  isNotNULL,
  getCommunityIds,
  sanitizeFilterObject,
  isEmpty,
  getScoreColorList,
  getMwcList,
  isDateInRange,
  isDateValid,
} from "../../../utils/helpers";
import {
  prospectListFilterSchema,
  prospectListFilter,
} from "../../../utils/prospect-list-filter-schema";
import {
  GET_ALLRANKINGS,
  ALL_REALTORS_NAMES,
  GET_ALL_STATES,
} from "../../../graphql/queries";
import {
  GET_AVAILABLE_HOMESITES,
  GET_HOMESITES,
  GET_AVAILABLE_OTHERS,
  GET_OTHERVALUES,
  GET_PROSPECT_FILTERS,
  GET_PROSPECT_FILTER_BYID,
  GET_AVAILABLE_FLOORPLANS,
  GET_CUSTOMER_ORIGINATION_AREA
} from "../../../graphql/queries";

/**
 * This component is used to render in the prospect filter popup. It renders the actual prospect filter form
 * {@link ProspectsListFilter} and the dropdown at the top to show the saved filters list. On selection of any saved
 * filter, its values are assigned to the homeowner filter form.
 * @param {Object} props
 */
const ProspectFilter = (props) => {
  const [appliedFilterName, setAppliedFilterName] = useState(null);
  const [values, setValues] = useState({});
  const [isValid, setIsValid] = useState(true);

  const { currentCard } = props;
  const applySaveFilters = (filterId, filterName) => {
    setAppliedFilterName(filterName);
    if (!filterId) {
      setIsValid(true);
      setValues({});
    } else {
      props.getProspectFilterDetail.fetchMore({
        variables: { input: filterId },
        updateQuery: (prev, { fetchMoreResult }) => {
          const { created_from, created_to } =
            fetchMoreResult.getProspectFilters[0];
          if (currentCard.type === cardsType.THIS_MONTH) {
            if (
              !isDateInRange(
                created_from,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              ) ||
              !isDateInRange(
                created_to,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              )
            ) {
              setIsValid(false);
              setValues({ ...prospectListFilter });
            } else {
              setIsValid(true);
              setValues(fetchMoreResult.getProspectFilters[0]);
            }
          } else if (currentCard.type === cardsType.TODAY) {
            if (
              !isDateValid(
                created_from,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              )
            ) {
              setIsValid(false);
              setValues({ ...prospectListFilter });
            } else {
              setIsValid(true);
              setValues(fetchMoreResult.getProspectFilters[0]);
            }
          } else {
            setValues(fetchMoreResult.getProspectFilters[0]);
          }
        },
      });
    }
  };

  return (
    <div>
      <div className="form-row align-items-center mb-3">
        <div className="col-m6">
          <h6 className="mb-0">Filters</h6>
        </div>
        <div className="col-" style={{ textAlign: "right" }}>
          {/* <label class="control-label">My Filters</label> */}
          <DropdownButton
            variant="secondary"
            id="filter-item-btn"
            key="down"
            title={
              appliedFilterName ||
              props.filter.filter_name ||
              "My Saved Filters"
            }
            className="filters-my-saved"
          >
            {isNotNULL(props.getProspectFilters.getProspectFilters) &&
              props.getProspectFilters.getProspectFilters.map((item) => {
                return (
                  <Dropdown.Item
                    onSelect={() => applySaveFilters(item.id, item.filter_name)}
                    key={item.id}
                    eventKey={item.id}
                  >
                    {item.filter_name}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
        </div>
      </div>

      <ProspectListFilterForm
        savedValues={values}
        isDisabled={!isValid}
        appliedFilterName={appliedFilterName}
        {...props}
      />
    </div>
  );
};

/**
 * This component is used to render prospect filter form. It contains rank, home sites, floorplan, state, realtor, custom filter select dropdowns and reservation, video text, flagged check boxes.  . At the
 * bottom it contains an input field to name the filter and save it. Apply filter button remains disabled unless some
 * value on filter fields is selected. And advance filter will show some additional fields to add in the filter. On submit setFilter is called, which triggers table refetch.
 * @param {Object} props
 */
const ProspectsListFilter = (props) => {
  const {
    values,
    setFilter,
    setShow,
    appliedFilterName,
    currentCard,
    isDisabled,
  } = props;

  useEffect(() => {
    if (appliedFilterName !== null) props.resetForm({ ...prospectListFilter });
  }, [appliedFilterName]);

  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom));
    return isEmpty(filters);
  };

  const handleFilters = () => {
    // const {filter_name: _, ...filterValue} = values;
    /*
    To enable front end filtering, do not call this function, simply uncomment above
    code and pass 'filterValue' to 'setFilter'.
    */
    setFilter(getProspectFilterInput(values, "APPLY", currentCard));
    setShow(false);
  };

  let community_ids = getCommunityIds(props.selectedCommunity);

  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-6 col-md-6">
          <Field
            label="Created From"
            name="created_at[0]"
            id="created_at[0]"
            disabled={isDisabled}
            minDate={
              currentCard.type === cardsType.TODAY ||
                currentCard.type === cardsType.THIS_MONTH ||
                currentCard.type === cardsType.THIS_YEAR
                ? new Date(currentCard.filter.create_start_date)
                : null
            }
            maxDate={
              currentCard.type === cardsType.TODAY
                ? new Date(currentCard.filter.create_start_date)
                : currentCard.type === cardsType.THIS_MONTH ||
                  currentCard.type === cardsType.THIS_YEAR
                  ? new Date(currentCard.filter.create_end_date)
                  : new Date()
            }
            component={DatePickerView}
          />
        </div>
        <div className="col-6 col-md-6">
          <Field
            label="Created To"
            name="created_at[1]"
            id="created_at[1]"
            disabled={isDisabled}
            minDate={
              currentCard.type === cardsType.TODAY ||
                currentCard.type === cardsType.THIS_MONTH ||
                currentCard.type === cardsType.THIS_YEAR
                ? new Date(currentCard.filter.create_start_date)
                : null
            }
            maxDate={
              currentCard.type === cardsType.TODAY
                ? new Date(currentCard.filter.create_start_date)
                : currentCard.type === cardsType.THIS_MONTH ||
                  currentCard.type === cardsType.THIS_YEAR
                  ? new Date(currentCard.filter.create_end_date)
                  : new Date()
            }
            component={DatePickerView}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-6 col-md-6">
          <Field
            label="Last Activity From"
            name="updated_at[0]"
            id="updated_at[0]"
            maxDate={new Date()}
            disabled={isDisabled}
            component={DatePickerView}
          />
        </div>
        <div className="col-6 col-md-6">
          <Field
            label="Last Activity To"
            name="updated_at[1]"
            id="updated_at[1]"
            maxDate={new Date()}
            disabled={isDisabled}
            component={DatePickerView}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="rank.id"
            label="Rank"
            id="rank.id"
            disabled={props.getAllRankings.getAllRankings ? false : true}
            component={MultiSelectField}
            children={props.getAllRankings.getAllRankings}
            defaultValue={props.values.rank.id || null}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <DefaultQuery
            query={
              community_ids.length ? GET_AVAILABLE_HOMESITES : GET_HOMESITES
            }
            variables={{ community_ids }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              let fetchType = community_ids.length
                ? "getAvailableHome_Sites"
                : "getAllHome_Sites";

              let defaultValue = loading
                ? "Loading ..."
                : error
                  ? error.message
                  : "Select...";
              return (
                <Field
                  name="home_site.id"
                  label="Home Sites"
                  id="home_site.id"
                  classStyle="filter-select-field"
                  component={SelectField}
                >
                  <option value="">{defaultValue}</option>
                  {data && isNotNULL(data[fetchType])
                    ? data[fetchType].map((home_site, index) => (
                      <option key={index} value={home_site.id}>
                        {home_site.name}
                      </option>
                    ))
                    : null}{" "}
                </Field>
              );
            }}
          </DefaultQuery>
        </div>
        <div className="col-md-6">
          <DefaultQuery
            query={GET_AVAILABLE_FLOORPLANS}
            variables={{ community_ids }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              let fetchType = "getAllFloor_Plans";

              let defaultValue = loading
                ? "Loading ..."
                : error
                  ? error.message
                  : "Select...";
              return (
                <Field
                  name="floor_plan.id"
                  label="Floorplan"
                  id="floor_plan.id"
                  classStyle="filter-select-field"
                  disabled={data && data[fetchType] ? false : true}
                  component={SelectField}
                >
                  <option value="">{defaultValue}</option>
                  {data && isNotNULL(data[fetchType])
                    ? data[fetchType].map((floor_plans, index) => (
                      <option
                        key={index}
                        value={floor_plans.id}
                        selected={
                          values.floor_plans &&
                          floor_plans.id === values.floor_plans.id
                        }
                      >
                        {floor_plans.name}
                      </option>
                    ))
                    : null}
                </Field>
              );
            }}
          </DefaultQuery>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <Field
            name="state.id"
            label="State"
            id="state.id"
            classStyle="filter-select-field"
            disabled={props.getAllStates.getAllStates ? false : true}
            component={SelectField}
          >
            <option value="">Select...</option>
            {props.getAllStates.getAllStates &&
              props.getAllStates.getAllStates.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
          </Field>
        </div>
        <div className="col-md-6">
          <Field
            name="realtor_id"
            label="Realtor"
            id="realtor_id"
            classStyle="filter-select-field"
            disabled={props.getAllRealtors.getRealtorsData ? false : true}
            component={SelectField}
          >
            <option value="">Select...</option>
            <option key="-1" value="-1">
              Any Realtor
            </option>
            {props.getAllRealtors.getRealtorsData &&
              props.getAllRealtors.getRealtorsData.map((realtor) => (
                <option key={realtor.id} value={realtor.id}>
                  {realtor.first_name} {realtor.last_name}
                </option>
              ))}
          </Field>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6">
          <DefaultQuery
            query={
              community_ids.length ? GET_AVAILABLE_OTHERS : GET_OTHERVALUES
            }
            variables={{ community_ids }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              let fetchType = community_ids.length
                ? "getAvailableOther_Dropdown_Values"
                : "getAllOther_Dropdown_Values";

              let defaultValue = loading
                ? "Loading ..."
                : error
                  ? error.message
                  : "Select...";
              return (
                <Field
                  name="others.id"
                  label="Custom Filter"
                  id="others.id"
                  classStyle="filter-select-field"
                  component={SelectField}
                >
                  <option value="" key={0}>
                    {defaultValue}
                  </option>
                  {data && isNotNULL(data[fetchType])
                    ? data[fetchType].map((others, index) => (
                      <option key={index} value={others.id}>
                        {others.name}
                      </option>
                    ))
                    : null}
                </Field>
              );
            }}
          </DefaultQuery>
        </div>

        <div className="col-md-6">
          <Field
            name="motivation"
            label="Motivation"
            id="motivation"
            classStyle="filter-select-field"
            component={SelectField}
          >
            {getMwcList().map((others, index) => (
              <option key={index} value={others.eventKey}>
                {others.value}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6">
          <Field
            label="Prospect Source"
            name="customer_origination_area.id"
            id="customer_origination_area.id"
            classStyle="filter-select-field"
            disabled={isDisabled}
            component={SelectField}
            style={{ width: "100%" }}
          >
            <option value={null}>Select...</option>
            {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
              props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                (option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                )
              )}
          </Field>
        </div>
      </div>

      <div className="form-row d-flex justify-content-between w-100 px-2">
        <Field
          id="reservation"
          label={
            props.getTitleCustomizations.titleCustomizations
              ? props.getTitleCustomizations.titleCustomizations[0].value
              : "Loading..."
          }
          name="reservation"
          component={CheckboxField}
        />
        <Field
          id="video_text"
          label={
            props.getTitleCustomizations.titleCustomizations
              ? props.getTitleCustomizations.titleCustomizations[1].value
              : "Loading..."
          }
          name="video_text"
          component={CheckboxField}
        />
        <div className="filter-flag">
          <Field
            id="red_flag"
            label={<FaFlag style={{ color: "red" }} />}
            name="red_flag"
            component={CheckboxField}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12">
          <div className="form-row d-flex align-items-center px-2">
            <CustomButton
              btnValue="APPLY FILTERS"
              className="filter-apply-btn"
              onClick={handleFilters}
              disabled={isDisabled || enableFormSubmit(values)}
            />
            {/* <a
              onClick={advanceFilterHandler}
              href="javascript:void(0)"
              className="ml-2 d-block"
            >
              {values.advanceFilters ? "Basic Filters" : "Advance Filters"}
            </a> */}
          </div>
        </div>
      </div>
      <hr className="light" />
      <h6 className="mb-3">My Filters</h6>
      <div className="form-row px-1 align-content-center">
        <Field
          label="Filter Name"
          name="filter_name"
          id="filter_name"
          type="text"
          disable={isDisabled}
          component={TextField}
          // className="form-control"
          style={{ borderColor: "rgba(255, 255, 255, .2)" }}
        />
        <div className="d-flex align-items-center ml-1 mt-1">
          <CustomButton
            btnValue="SAVE FILTER"
            type="submit"
            color="green"
            disabled={
              props.isSubmitting || isDisabled || enableFormSubmit(values)
            }
          />
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

/*
 This function formats form-values to the expected 'apply-filter'
 and 'save-filter' input format. Filter Schema saves values
 differently so we can easily apply front-end filtering if we want.
*/
const getProspectFilterInput = (
  {
    rank,
    floor_plan,
    home_site,
    others,
    reservation,
    video_text,
    red_flag,
    filter_name,
    state,
    realtor_id,
    created_at,
    updated_at,
    motivation,
    customer_origination_area
  },
  type,
  currentCard
) => {
  const common = {
    floor_plan_id: floor_plan && floor_plan.id !== "" ? floor_plan.id : null,
    home_site_id: home_site && home_site.id !== "" ? home_site.id : null,
    reservation,
    video_text: video_text,
    realtor_id: realtor_id === -1 ? undefined : realtor_id,
    all_realtor: realtor_id === -1 ? true : false,
    state_id: state && state.id !== "" ? state.id : null,
    filter_name: filter_name && filter_name.trim(),
    motivation: motivation === "true" ? true : false,
    customer_origination_area_id:
      (customer_origination_area && customer_origination_area.id) || undefined,
  };

  return type === "APPLY"
    ? {
      ...common,
      create_start_date:
        (created_at ||
          currentCard.type === cardsType.TODAY ||
          currentCard.type === cardsType.THIS_MONTH) &&
        (created_at[0] || currentCard.filter?.create_start_date || null),
      create_end_date:
        (created_at ||
          currentCard.type === cardsType.TODAY ||
          currentCard.type === cardsType.THIS_MONTH) &&
        (created_at[1] || currentCard.filter?.create_end_date || null),
      update_start_date: updated_at && (updated_at[0] || null),
      update_end_date: updated_at && (updated_at[1] || null),
      rank_id: rank && rank.id !== "" ? rank.id : null,
      others_id: others && others.id !== "" ? others.id : null,
      flagged: red_flag,
    }
    : {
      ...common,
      created_from:
        created_at &&
        (created_at[0] ? moment(created_at[0]).format("LL") : null),
      created_to:
        created_at &&
        (created_at[1] ? moment(created_at[1]).format("LL") : null),
      last_activity_from:
        updated_at &&
        (updated_at[0] ? moment(updated_at[0]).format("LL") : null),
      last_activity_to:
        updated_at &&
        (updated_at[1] ? moment(updated_at[1]).format("LL") : null),
      ranking_id: rank && rank.id ? rank.id : [],
      custom_filter_id: others && others.id !== "" ? others.id : null,
      red_flag: red_flag,
    };
};

/*
 This function formats filter/saved values to the
 expected form-values input format.
*/
const getProspectFormFilterInput = (toExtract, type) => {
  const { reservation, video_text } = toExtract;

  const common = {
    reservation: reservation,
    video_text: video_text,
    filter_name: toExtract.filter_name,
    motivation: toExtract?.motivation,
  };
  return type === "FILTER"
    ? {
      ...prospectListFilter,
      ...common,
      rank: { id: toExtract.rank_id },
      floor_plan: { id: toExtract.floor_plan_id },
      home_site: { id: toExtract.home_site_id },
      others: { id: toExtract.others_id },
      state: { id: toExtract.state_id },
      realtor_id: toExtract.all_realtor ? -1 : toExtract.realtor_id,
      red_flag: toExtract.flagged,
      created_at: [toExtract.create_start_date, toExtract.create_end_date],
      updated_at: [toExtract.update_start_date, toExtract.update_end_date],
    }
    : {
      ...prospectListFilter,
      ...common,
      rank: {
        id:
          (toExtract.Rankings &&
            toExtract.Rankings.length &&
            toExtract.Rankings.map((item) => item.id)) ||
          undefined,
      },
      floor_plan: {
        id: (toExtract.Floor_Plan && toExtract.Floor_Plan.id) || undefined,
      },
      home_site: {
        id: (toExtract.Home_Site && toExtract.Home_Site.id) || undefined,
      },
      others: {
        id:
          (toExtract.CustomFilter && toExtract.CustomFilter.id) || undefined,
      },
      state: { id: (toExtract.State && toExtract.State.id) || undefined },
      realtor_id:
        (toExtract.all_realtor ? -1 : toExtract.Realtor?.id) || undefined,
      red_flag: toExtract.red_flag || undefined,
      created_at: [
        toExtract.created_from &&
        moment(new Date(toExtract.created_from)).toDate(),
        toExtract.created_to &&
        moment(new Date(toExtract.created_to)).toDate(),
      ],
      updated_at: [
        toExtract.last_activity_from &&
        moment(new Date(toExtract.last_activity_from)).toDate(),
        toExtract.last_activity_to &&
        moment(new Date(toExtract.last_activity_to)).toDate(),
      ],
    };
};

const ProspectListFilterForm = withFormik({
  mapPropsToValues: (props) => {
    const { filter, savedValues } = props;
    if (isNotNULL(savedValues) && Object.entries(savedValues).length > 0)
      return getProspectFormFilterInput(savedValues, "SAVED");
    return isNotNULL(filter)
      ? getProspectFormFilterInput(filter, "FILTER")
      : prospectListFilter;
    /*to enable front-end filtering do not format filter values, simply use 'filter'*/
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .addProspectFilter({
        variables: { input: getProspectFilterInput(values, "SAVE") },
      })
      .then((res) => {
        setSubmitting(false);
        props.onSaveFilter(res.data.addProspectFilter.message);
        if (res.data.addProspectFilter.code === 200) {
          props.getProspectFilters.refetch();
        }
      });
  },
  displayName: "prospect-list-filter",
  enableReinitialize: true,
  validationSchema: prospectListFilterSchema,
})(ProspectsListFilter);

const ListProspectsFilters = compose(
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(GET_TITLE_CUSTOMIZATIONS, { name: "getTitleCustomizations" }),
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_ALLRANKINGS, { name: "getAllRankings" }),
  graphql(GET_PROSPECT_FILTERS, { name: "getProspectFilters" }),
  graphql(GET_PROSPECT_FILTER_BYID, {
    name: "getProspectFilterDetail",
    options: (props) => ({ variables: { input: null } }),
  }),
  graphql(CREATE_PROSPECT_FILTER, { name: "addProspectFilter" }),
  graphql(ALL_REALTORS_NAMES, {
    name: "getAllRealtors",
    options: (props) => {
      return {
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(GET_ALL_STATES, { name: "getAllStates" })
)(ProspectFilter);

export default withUserContext(ListProspectsFilters);
