import React, { useState, useEffect, useContext } from "react";
import { Form, Field, withFormik } from "formik";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaBan } from "react-icons/fa";

import { leadSchema, leadForm } from "../../../utils/lead-form-schema";
import TextField from "../../../components/fields/text-field";
import AddEmailField from "../../../components/fields/add-email-field";
import SelectField from "../../../components/fields/select-field";
import MultiSelectField from "../../../components/fields/multi-select-field";
import TextAreaField from "../../../components/fields/text-area";
import CustomButton from "../../../components/custom-button/index";
import Dialog from "../../../components/dialog/dialog";
import ErrorFocus from "../../../components/error-focus/error-focus";
import {
  phoneDisplay,
  filterCommunitiesByDivisions,
} from "../../../utils/helpers";
import "../../../views/prospect/dashboard/prospect-dashboard.css";
import { SearchableRealtorSelect } from "../../../components/agencies-creatable-select";
import * as constants from "../../../utils/constants";
import { GET_ALL_LEAD_CLASSIFICATION_FILTERS } from "../../../graphql/queries";
import { DefaultQuery } from "../../../graphql/default-query";
import { Auth } from "../../../rbac/rbac";

//this form is being used for create lead edit lead and also create lead from webform

/**
 * This component is responsible to render the create edit form of the lead. It has text fields, text area, multiselect and add email select fields.
 * First name, last name and either primary or cell phone must be filled to submit the form. Primary emails of buyer one and 2 mst not be similar. Primary
 * email must not match any of the secondary emails. These are the most important validations of this form. On form
 * submit form-data it checks wether to create, edit, convert or recover, and it perfomrs the appropriate mutation accordingly.
 *
 * @param {Object} props formik-bag, and query/mutations passed from the container
 * @param {Object} props.data data formatted in the form of schema
 */
const LeadForm = (props) => {
  const {
    values,
    status,
    getOriginationAreas,
    setFieldValue,
    convertFormType,
    inActiveCommunities,
  } = props;
  const { is_csm } = useContext(Auth);

  const score = React.useMemo(() => [1, 2, 3, 4, 5], []);
  const [primarySecondaryClicked1, setPrimarySecondaryClicked1] = useState({});
  const [primarySecondaryClicked2, setPrimarySecondaryClicked2] = useState({});
  const [primaryClicked, setPrimaryClicked] = useState({});
  const [tooltipMessage, setTooltipMessage] = useState("");

  const findAndSetCustomerOrigination = (toFind, originationList) => {
    let result = originationList.find((item) => {
      return item.name === toFind;
    });
    result && setFieldValue("customer_origination_area_id", result.id);
  };

  useEffect(() => {
    if (getOriginationAreas?.getAllCustomer_Origination_Areas) {
      if (convertFormType === "WEBFORM") {
        findAndSetCustomerOrigination(
          constants.WEB_ORIGINATION_SOURCE,
          getOriginationAreas.getAllCustomer_Origination_Areas
        );
      } else if (convertFormType === "REALTOR REGISTRATION") {
        findAndSetCustomerOrigination(
          constants.REALTOR_ORIGINATION_SOURCE,
          getOriginationAreas.getAllCustomer_Origination_Areas
        );
      }
    }
  }, [getOriginationAreas, convertFormType]);

  useEffect(() => {
    let tooltipString = "Inactive Communities: ";
    inActiveCommunities &&
      inActiveCommunities.forEach((community, index) => {
        tooltipString =
          inActiveCommunities.length - 1 === index
            ? tooltipString + community.name
            : tooltipString + community.name + ",";
      });
    setTooltipMessage(tooltipString);
  }, [inActiveCommunities]);

  // this useEffect handles community value clearance on selection of division
  useEffect(() => {
    const { divisions, communities } = values.Lead;
    const listedCommunities = filterCommunitiesByDivisions(
      props.getAllCommunities?.getAllCommunities,
      divisions
    );

    // compares form community values and listedCommunities
    let selectedCommunities =
      communities &&
      communities.reduce((defaults, item) => {
        const comm =
          listedCommunities &&
          listedCommunities
            .filter((listItem) => listItem.id === item)
            .find((item) => item);
        if (comm) {
          defaults.push(comm.id);
        }
        return defaults;
      }, []);

    selectedCommunities.length &&
      setFieldValue("Lead.communities", selectedCommunities);
  }, [values.Lead.divisions, props.getAllCommunities]);

  const handleMergeBody = () => {
    return (
      <div>
        {status.showAddLeadErrorMessage}
        {status.showAddLeadErrorLeadCommunities?.length > 0 && (
          <div>
            <p>This lead exists as a Lead in the following communities:</p>
            {status.showAddLeadErrorLeadCommunities.map((item) => (
              <strong>{item.name}</strong>
            ))}
          </div>
        )}

        {status.showAddLeadErrorProspectCommunities?.length > 0 && (
          <div>
            <p>This lead exists as a Prospect in the following communities:</p>
            {status.showAddLeadErrorProspectCommunities.map((item) => (
              <strong>{item.name}</strong>
            ))}
          </div>
        )}
        <p>Do you want to merge?</p>
      </div>
    );
  };

  const handleCancelMerge = () => {
    props.setStatus({
      showAddLeadError: false,
      showAddLeadErrorMessage: null,
      showAddLeadErrorLeadCommunities: null,
      showAddLeadErrorProspectCommunities: null,
    });
  };
  const handleCloseArchiveDialog = () => {
    props.setStatus({
      showArchivedLeadError: false,
      showArchivedLeadErrorMessage: null,
    });
  };

  const handleMerge = () => {
    let submitValues = Object.assign({}, values);
    submitValues = normalizeData(submitValues);

    props
      .addLeadDuplicate({
        variables: { input: submitValues },
      })
      .then((res) => {
        if (res.data.addLeadDuplicate.code === 200) {
          props.onSubmit();
          props.setNotificationProps({
            variables: {
              open: true,
              message: res.data.addLeadDuplicate.message,
            },
          });
        }
      });
    handleCancelMerge();
  };

  return (
    <Form className={"form-horizontal"} noValidate>
      <Dialog
        show={status ? status.showAddLeadError : false}
        size="md"
        title="Creation Issue"
        onHide={handleCancelMerge}
        body={status ? handleMergeBody() : null}
        click={handleMerge}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={status ? status.showArchivedLeadError : false}
        size="md"
        title="Creation Issue"
        onHide={handleCloseArchiveDialog}
        body={status ? status.showArchivedLeadErrorMessage : null}
        click={handleCloseArchiveDialog}
        clickname="OK"
      />

      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Lead</div>
            <div className="form_TitleBig mt-1">
              <div className="d-flex justify-content-center align-items-center">
                {inActiveCommunities?.length ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2022 }}>
                        {tooltipMessage}
                      </Tooltip>
                    }
                  >
                    <FaBan size={18} className="td-warning" />
                  </OverlayTrigger>
                ) : null}
                <h2>
                  {props.editForm
                    ? `${values.first_name_1} ${values.last_name_1}`
                    : "Create New"}
                </h2>
              </div>
            </div>
          </div>
          <div className="d-flex pr-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className={`form_CancelButton`}
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className={`form_SubmitButton`}
                type="submit"
                disabled={props.isSubmitting}
                btnValue={props.editForm ? "UPDATE" : "CREATE"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#1</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_1"
              id="first_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_1"
              id="last_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_1"
                id="primary_email_1"
                disable={props.data && props.data.disable_primary_email_1}
                type="email"
                refFields={[
                  {
                    value: primarySecondaryClicked1,
                    setter: setPrimarySecondaryClicked1,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                required={!values.formatted_cell_phone_1 || false}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_1"
                id="formatted_cell_phone_1"
                isCellPhone={true}
                type="text"
                required={!values.primary_email_1 || false}
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_1"
              id="secondary_emails_1"
              initValue={
                props.editForm || props.recoverForm
                  ? values.secondary_emails_1
                  : []
              }
              type="text"
              refFields={[
                {
                  value: primarySecondaryClicked1,
                  setter: setPrimarySecondaryClicked1,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#2</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_2"
              id="first_name_2"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_2"
              id="last_name_2"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_2"
                id="primary_email_2"
                disable={props.data && props.data.disable_primary_email_2}
                type="email"
                refFields={[
                  {
                    value: primarySecondaryClicked2,
                    setter: setPrimarySecondaryClicked2,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_2"
                id="formatted_cell_phone_2"
                isCellPhone={true}
                type="text"
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_2"
              id="secondary_emails_2"
              initValue={
                props.editForm || props.recoverForm
                  ? values.secondary_emails_2
                  : []
              }
              type="text"
              refFields={[
                {
                  value: primarySecondaryClicked2,
                  setter: setPrimarySecondaryClicked2,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-section">
            <h5>Optional Fields</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="OSC Notes"
              name="osc_notes"
              id="osc_notes"
              type="text"
              disable={is_csm()}
              rows={3}
              component={TextAreaField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Address"
              name="street_address"
              id="street_address"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="City"
              name="city"
              id="city"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-4">
            <Field
              label="State"
              name="state_id"
              id="state_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates.getAllStates &&
                props.getAllStates.getAllStates.map((state, index) => (
                  <option key={index} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </Field>
          </div>
          <div className="col-md-2">
            <Field
              label="Zip"
              name="zip"
              id="zip"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Quality Score"
              name="Lead.quality_score"
              id="Lead.quality_score"
              component={SelectField}
            >
              <option value="">Select...</option>
              {score.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Field>
          </div>
          <div className="col-md-6">
            <Field
              label="Engagement Score"
              name="Lead.engagement_score"
              id="Lead.engagement_score"
              component={SelectField}
            >
              <option value="">Select...</option>
              {score.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Division"
              name="Lead.divisions"
              id="Lead.divisions"
              menuPosition="top"
              required={true}
              component={MultiSelectField}
              children={props.getDivisions?.getAllDivisions}
              defaultValue={values.Lead.divisions || null}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Communities"
              name="Lead.communities"
              id="Lead.communities"
              menuPosition="top"
              disabled={
                !values.Lead.divisions?.length &&
                (!values.communities?.length ||
                  !values.Lead.communities?.length)
              }
              component={MultiSelectField}
              children={filterCommunitiesByDivisions(
                props.getAllCommunities?.getAllCommunities,
                values.Lead.divisions
              )}
              defaultValue={values.Lead.communities || null}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Realtor"
              name="realtor_id"
              id="realtor_id"
              disabled={true}
              component={SearchableRealtorSelect}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Lead Source"
              name="customer_origination_area_id"
              id="customer_origination_area_id"
              required={true}
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
                props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                  (option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </Field>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <DefaultQuery
              query={GET_ALL_LEAD_CLASSIFICATION_FILTERS}
              fetchPolicy="cache-and-network"
            >
              {({ data, loading, error }) => {
                let defaultValue =
                  loading || error?.message
                    ? []
                    : data.getAllLeadClassificationFilters;

                return (
                  <Field
                    label="Lead Classification"
                    name="Lead.lead_classification_filter_ids"
                    id="Lead.lead_classification_filter_ids"
                    menuPosition="top"
                    component={MultiSelectField}
                    children={defaultValue}
                    defaultValue={
                      values.Lead.lead_classification_filter_ids || null
                    }
                  />
                );
              }}
            </DefaultQuery>
          </div>
          <div className="col-md-6">
            <Field
              label="Lead Notes"
              name="Lead.other_notes"
              id="Lead.other_notes"
              type="text"
              rows={3}
              component={TextAreaField}
            />
          </div>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

const updateLeadSubmit = (values, props, setSubmitting) => {
  delete values.communities;
  props
    .updateLead({
      variables: { input: values },
    })
    .then((res) => {
      setSubmitting(false);
      let message = null;
      if (res.data.updateLead.code === 200) {
        const isPresentInLoggedInCommunities =
          props.getLoggedInCommunities.getLoggedInUserCommunities.filter(
            (item) => values.Lead.communities.indexOf(item.id) !== -1
          );
        if (
          values.Lead.communities.length &&
          !isPresentInLoggedInCommunities.length &&
          props.isDetails
        ) {
          props.history.push("/lead-dashboard");
        } else {
          props.update();
        }
      }
      if (res.data.updateLead.code === 409) {
        if (res.data.updateLead.data && res.data.updateLead.data.length >= 1) {
          let body = [];
          body.push(res.data.updateLead.message);
          res.data.updateLead.data &&
            res.data.updateLead.data.forEach((item) => {
              body.push(<div>{item.name}</div>);
            });
          message = body;
        }
      }

      props.setNotificationProps({
        variables: {
          open: true,
          message: message || res.data.updateLead.message,
        },
      });
    });
};

const createLeadSubmit = (values, props, setStatus, setSubmitting) => {
  props
    .addLead({
      variables: { input: values },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.addLead.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: { open: true, message: res.data.addLead.message },
        });
      } else if (res.data.addLead.code === 449) {
        setStatus({
          showArchivedLeadError: true,
          showArchivedLeadErrorMessage: res.data.addLead.message,
        });
      } else {
        setStatus({
          showAddLeadError: true,
          showAddLeadErrorMessage: res.data.addLead.message,
          showAddLeadErrorLeadCommunities:
            res.data.addLead.data.leadCommunities,
          showAddLeadErrorProspectCommunities:
            res.data.addLead.data.prospectCommunities,
        });
      }
    });
};

//this section is used only when we recover archived to lead, its being directly call in handlesubmit!
const recoverLeadSubmit = (values, props, setSubmitting) => {
  props
    .addLeadDuplicate({
      variables: { input: values, overwrite: true },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.addLeadDuplicate.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: { open: true, message: res.data.addLeadDuplicate.message },
        });
      }
    });
};

/**
 * This function is used to format submitted data back to the form in which backend expects it in variables of
 * mutation. delete some properties, reassign string values to  null etc.
 * @param {Object} submitValues
 */
const normalizeData = (submitValues) => {
  delete submitValues.hidden_secondary_emails_2;
  delete submitValues.hidden_secondary_emails_1;
  delete submitValues.formatted_cell_phone_1;
  delete submitValues.formatted_cell_phone_2;
  delete submitValues.realtor_id;
  delete submitValues.disable_primary_email_1;
  delete submitValues.disable_primary_email_2;

  if (submitValues.primary_email_1 === "") {
    submitValues.primary_email_1 = null;
  }
  if (submitValues.cell_phone_1 === "") {
    submitValues.cell_phone_1 = null;
  }
  if (submitValues.primary_email_2 === "") {
    submitValues.primary_email_2 = null;
  }
  if (submitValues.cell_phone_2 === "") {
    submitValues.cell_phone_2 = null;
  }

  return submitValues;
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    if (props.editForm) {
      const { cell_phone_1, cell_phone_2 } = props.data;
      const formatted_cell_phone_1 = phoneDisplay(cell_phone_1);
      const formatted_cell_phone_2 = phoneDisplay(cell_phone_2);
      return { formatted_cell_phone_1, formatted_cell_phone_2, ...props.data };
    } else if (props.convertForm || props.recoverForm) {
      return { ...props.data };
    }
    return { ...leadForm };
  },

  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    let submitValues = Object.assign({}, values);

    submitValues = normalizeData(submitValues);

    props.recoverForm
      ? recoverLeadSubmit(submitValues, props, setSubmitting)
      : props.editForm
        ? updateLeadSubmit(submitValues, props, setSubmitting)
        : createLeadSubmit(submitValues, props, setStatus, setSubmitting);
  },
  validationSchema: leadSchema,
  displayName: "new-lead-form",
})(LeadForm);
