import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import TableView, {
  TextHeader,
  TextCellWithMapper,
  TextCell,
  ListCell,
  iconCell,
  PhoneCell,
  TextObjectWithMapper,
  EmailCell,
  CheckboxCell,
  SortableHeader,
  stringComparator
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
  phoneDisplay
} from "../../utils/helpers";
import reducer, { getInitialState } from "./activity-report-reducer";
import CustomLoader from "../../components/custom-loader";
import {
  FaRedo,
  FaCalendarCheck,
  FaSms,
  FaEnvelope,
  FaPhone,
  FaHome
} from "react-icons/fa";
import ActivityReportFilterView from "./activity-report-filter";

/** @module ActivityReportTableModule */

/**
 * This component is a table used to display all the activity types. This table does not contain any title or search
 * bar. It contains pagination, filtering, and export to csv options. It has no hover-actions. It shows multiple 
 * columns displaying basic information related to activities. Table uses reducer to maintain its state. It also 
 * accesses store to check if table parameters are already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of activities to be shown on table
 * @param {Object} props.getTableParams it contains table info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table 
 */
const ActivityReportTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {
  const cacheParams = getCardParams("Activity Report", tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const { report, loading, render, userPageCount, pageSize } = state;

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  /**
   * This is a function which is responsible for returning the appropriate icon depending on the type of the activity
   * 
   * @function
   * @inner
   * @memberof module:ActivityReportTableModule
   * @see {@link module:ActivityReportTableModule~ActivityReportTable}
   */
  const handleIcon = (item) => {
    if (item.activity_type.includes("Text")) {
      return FaSms;
    } else if (item.activity_type.includes("Phone")) {
      return FaPhone;
    } else if (item.activity_type.includes("Appointment")) {
      return FaCalendarCheck;
    } else if (item.activity_type.includes("Follow")) {
      return FaRedo;
    } else if (item.activity_type.includes("Walk")) {
      return FaHome;
    } else {
      return FaEnvelope;
    }
  };

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading)
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        report:
          data &&
          data.getActivityReport &&
          data.getActivityReport.activityReports,
        userPageCount: Math.ceil(data.getActivityReport.total_count / pageSize),
      });
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

  /**
   * This defines columns of the table. It has multiple columns, displaying information of the activities. None of
   * the columns are sortable.
   * 
   * @constant
   * @memberof module:ActivityReportTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: {
          component: iconCell,
          IconMapper: (field) => handleIcon(field),
        },
      },
      {
        id: "1",
        header: "Activity Type",
        accessor: "activity_type",
        component: TextHeader,
        cell: {
          component: TextCell,
        },
      },
      {
        id: "2",
        header: "Activity Title",
        accessor: "activity_title",
        component: TextHeader,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor]
        },
      },
      {
        id: "3",
        header: "Appointment Type",
        accessor: "appointment_type",
        component: TextHeader,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field.activity_type === 'Appointment Held' || field.activity_type === 'Appointment Set'
              ? field[accessor]
              : '',
        },
      },
      {
        id: "4",
        header: "Customer Name",
        accessor: "customer_name",
        component: TextHeader,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor] || "———"
        },
      },
      {
        id: "5",
        header: "Customer Email",
        accessor: "customer_email",
        component: TextHeader,
        cell: {
          component: EmailCell,
          check: "customer_email_status"
        },
      },
      {
        id: "6",
        header: "Customer Phone",
        accessor: "customer_phone",
        component: TextHeader,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: {
          component: PhoneCell,
        },
      },
      {
        id: "7",
        header: "Customer Type",
        accessor: "customer_types",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].map(({ name }) => name) : "",
        cell: {
          component: TextObjectWithMapper,
          mapper: (field, accessor) => field[accessor]
        },
      },
      {
        id: "8",
        header: "User",
        accessor: "csm",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name + " " + field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name
        }
      },
      {
        id: "9",
        header: "Community",
        accessor: "communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].map(({ name }) => name) : "",
        cell: {
          component: ListCell,
        },
      },
      {
        id: "10",
        header: "Activity Date & Time",
        accessor: "activity_datetime",
        component: TextHeader,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
      {
        id: "11",
        header: "Source",
        accessor: "customer_origination_area.name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: TextCell,
          mapper: (field, accessor) => field?.customer_origination_area?.name || "———",
        },
      },
      {
        id: "12",
        header: "Traffic",
        accessor: "is_traffic_activity",
        component: TextHeader,
        cell: {
          component: CheckboxCell,
        },
      },
    ],
    []
  );

  /**
    * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
    * Table passes its current state so that we can update the table parameters in store and state, which are used in 
    * refetching the data with correct parameters.  
    * 
    * @function
    * @inner
    * @memberof module:ActivityReportTableModule
    * @see {@link module:ActivityReportTableModule~ActivityReportTable}
    */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }
    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
    };

    // update the state
    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    const pageCount = Math.ceil(
      data.getActivityReport.total_count / tableProps.pageSize
    );

    // if search term or filter is changed, bring the page to 0
    if (
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }
    // update total page count in the state
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", { userPageCount: pageCount });


    dispatch({ type: "UPDATE", payload: { ...variables, pageSize: tableProps.pageSize, loading: true } });
    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "Activity Report",
            params: variables,
          }),
        },
      })
      .then((res) => {
        callDispatch("UPDATE", { loading: false });
      });
  });

  const tableOptions = {
    title: "",
    FilterItems: ActivityReportFilterView,
  };
  return (
    <div>
      <FadeIn show={render}>
        <div>
          <TableView
            columns={columns}
            removeSearchField
            data={report}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading ? data.getActivityReport.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};

export default ActivityReportTable;
