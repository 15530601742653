import React from "react";
import { graphql, compose } from "react-apollo";
import { GET_ACTIVITY_TYPES, GET_CUSTOMER_ORIGINATION_AREA } from "../../graphql/queries";
import { Form, Field, withFormik } from "formik";
import { ActivityReportFilterForm, ActivityReportSchema } from "./activity-report-filter-schema";
import { isNotNULL } from "../../utils/helpers";
import SelectFieldNonInt from "../../components/fields/select-field-non-int";
import CustomButton from "../../components/custom-button";
import CheckboxField from "../../components/fields/checkbox-field";
import SelectField from "../../components/fields/select-field";

/**
 * This component is used to render filter form for activity-report table.
 * It is a very basic form containing a select field to list down all the types of activities and a checkbox to filter traffic.
 * This way user can filter out the data on table by activity type.
 * On clicking apply filter button, filters are set inside table which in turn causes table refetching the table data with new filter.
 * @param {Object} props 
 */
const ActivityReportFilter = (props) => {
  const { getAllActivityTypes, isDisabled, getOriginationAreas, } = props
  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="type"
            label="Types"
            id="type"
            classStyle="filter-select-field"
            component={SelectFieldNonInt}
          >
            <option value="">All Types</option>
            {getAllActivityTypes?.getAllActivityTypes?.map((type, index) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
          </Field>
        </div>
        <div className="col-md-12">
          <Field
            label="Source"
            name="customer_origination_area_id"
            id="customer_origination_area_id"
            classStyle="filter-select-field"
            disabled={isDisabled}
            component={SelectField}
            style={{ width: "100%" }}
          >
            <option value={null}>Select...</option>
            {
                props.getOriginationAreas?.getAllCustomer_Origination_Areas?.map(
                  (option) => (
                    <option key={option.name} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
          </Field>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="traffic"
            label="Traffic"
            id="traffic"
            classStyle="filter-select-field"
            component={CheckboxField}
          />
        </div>
      </div>

      <div className="form-row">
        <CustomButton
          type="submit"
          btnValue="APPLY FILTERS"
          className="filter-apply-btn"
        />
      </div>
    </Form>
  );
};

const ActivityReportFilterFormView = withFormik({
  mapPropsToValues: (props) => {
    const { filter } = props;
    return isNotNULL(filter) ?
      {
        type: filter.activity_type,
        traffic: filter.traffic,
        customer_origination_area_id: filter.customer_origination_area_id
      }
      : ActivityReportFilterForm;
  },
  handleSubmit: (values, { props }) => {
    props.setShow(false);
    props.setFilter({
      activity_type: values.type,
      traffic: values.traffic,
      customer_origination_area_id: values.customer_origination_area_id
    });
  },
  displayName: "activity-report-filter-form",
  enableReinitialize: true,
  validationSchema: ActivityReportSchema,
})(ActivityReportFilter)

const MergeReportActivityContainer = compose(
  graphql(GET_ACTIVITY_TYPES, { name: "getAllActivityTypes" }),
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
)(ActivityReportFilterFormView);

export default MergeReportActivityContainer;