import gql from "graphql-tag";

const GET_TASKS_APPOINTMENTS = gql`
  query getAllAppointments(
    $limit: Int
    $pageNum: Int
    $filter: AppointmentsAndFollowupsFilter
    $search: String
    $order: String
  ) {
    getAllAppointments_And_Followups(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      order: $order
    ) {
      Appointment_And_Followup {
        done
        tentative
        tentative_date
        prospect_id
        lead_id
        appointment_id
        follow_up_id
        first_name_1
        last_name_1
        first_name_2
        last_name_2
        primary_email_1
        primary_email_2
        primary_email_1_status
        primary_email_2_status
        cell_phone_1
        cell_phone_2
        zoom_meeting_link
        rank {
          id
          name
        }
        title
        details
        due_date
        end_date
        created_at
        creator {
          first_name
          last_name
        }
        csm {
          id
          first_name
          last_name
          is_active
        }
        community {
          id
          name
          is_active
        }
        red_flag {
          description
          createdAt
          updatedAt
        }
        Appointment_Type {
          id
          name
        }
        customer_type
      }
      total_count
    }
  }
`;
export default GET_TASKS_APPOINTMENTS;
