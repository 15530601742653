import React, { useState } from 'react'
import { getIssueEmail, InactivityIcon, goBack, formatDataForCalendar } from '../../../utils/helpers';
import { UpdateHomeownerActivityContainer } from '../../common-lead-prospect-forms/activity-container';
import { SelectBar } from '../../../containers';
import { FaChevronLeft } from 'react-icons/fa';
import { HOMEOWNER } from "../../../utils/constants"
import NoDataPage from '../../no-data-page/no-data-page';
import logo from "../../../images/graphic-no-prospect.png";
import LoadingWrapper from '../../../components/loading-wrapper';
import CustomButton from '../../../components/custom-button';
import Header from '../../../components/dashboard-title';
import Calendar from "../../../components/calendar/calendar";
import HomeownerDetail from '../details/homeowner-details-card';
import PastActivitiesView from '../../common-lead-prospect-details/past-activities';
import CalendarPopover from '../../common-lead-prospect-details/calendar-popover'
import NoteReminderView from '../../common-lead-prospect-details/notes-card';
import HomeownerCallButton from './homeowner-call-button';
import ActivityButton from '../../../components/activity-button';
import DemoteHomeOwnerToLead from './homeowner-demote-button';

/**
 * This component is used to render details page of the homeowner. It is divided into four main parts. On the top
 * it renders {@link Header} component inside {@link SelectBar} to display homeonwer's community and his name. Right
 * below it, {@link BigCalendar} is displayed and next to it {@link HomeownerDetail} card is rendered ti show basic 
 * information of the homeowner. Right below Big calendar we have past-activities view and notes list view using
 * {@link NoteReminderView}. If teh homeowner is not available, no data page is displayed instead of details page. 
 * while the data is loading, a full page loader is rendered. 
 * @param {Object} props queries from the container are received as props 
 */
const HomeownerDetailsView = ({ getHomeownerDetail, history, ...props }) => {

  const [activity, setActivity] = useState(null);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [showEditPhoneCall, setShowEditPhoneCall] = useState(false);

  const submitHandler = (refetch, message) => {
    message &&
      props.setNotificationProps({
        variables: { open: true, message: message }
      });
    refetch();
  };

  const toggleShowActivityDrawer = activity => {
    setActivity(activity);
    if (activity.__typename === "PhoneCall" || activity.__typename === "Text") {
      setShowEditPhoneCall(!showEditPhoneCall);
    } else {
      setShowEditActivity(!showEditActivity)
    }
  };

  const hideEditDrawer = () => {
    setShowEditPhoneCall(false);
    setShowEditActivity(false);
    setActivity(null);
  };

  const homeownerDetailsView = (id, data, loading, refetch) => {
    let name =
      !loading ?
        data.getAllHomeOwners.Home_Owner &&
        ` ${data.getAllHomeOwners.Home_Owner[0].first_name_1} ${data.getAllHomeOwners.Home_Owner[0].last_name_1}` : ''
    name +=
      !loading && data.getAllHomeOwners.Home_Owner[0].first_name_2
        ? ` and ${data.getAllHomeOwners.Home_Owner[0].first_name_2} ${data.getAllHomeOwners.Home_Owner[0].last_name_2}`
        : "";

    return (
      <div>
        <LoadingWrapper
          loading={loading}
          component={
            <div className="col-padding col-md-12 col-12">
              <div className="root-row z4" id="prospect-detail-header">
                <SelectBar>
                  <div className="d-flex">
                    <CustomButton
                      onClick={() => goBack(history)}
                      id="back-to-dashboard-btn"
                      color="dark"
                      btnIcon={<FaChevronLeft />}
                      className="btn-back"
                    />
                    <Header
                      title={HOMEOWNER}
                      subtitle={name || ""}
                      community={!loading ? (
                        <div className="d-flex mr-2">
                          <span className="text-muted">:&nbsp;</span>{" "}
                          {InactivityIcon(
                            data.getAllHomeOwners.Home_Owner[0].community,
                            data.getAllHomeOwners.Home_Owner[0].community.name
                          )}
                        </div>
                      ) : null}
                    />
                  </div>
                  <div>
                    <DemoteHomeOwnerToLead
                      homeOwner={data?.getAllHomeOwners?.Home_Owner?.[0]}
                      showButton
                      btnValue="DEMOTE TO LEAD"
                      className="btn btn-primary ml-2" 
                      history={history}
                    />
                  </div>
                </SelectBar>
              </div>

              <div className="row-container main-responsive col-xl-12 row p-2 px-3">
                <div className="detail-view-main col-padding col-md-8 col-8">
                  <div
                    style={{
                      padding: "1rem",
                      marginTop: "0.5rem",
                      border: "1px solid #191b1d",
                      borderRadius: "0.25rem"
                    }}
                  >
                    <Calendar
                      id={id}
                      data={Object.assign(
                        {},
                        !loading ? data.getAllHomeOwners.Home_Owner[0] : {},
                      )}
                      onSubmit={submitHandler.bind(this, refetch)}
                      {...props}
                      popover={CalendarPopover}
                      editComp={toggleShowActivityDrawer}
                      type={HOMEOWNER}
                    />

                    <PastActivitiesView
                      type={HOMEOWNER}
                      pastActivitiesData={
                        !loading ? formatDataForCalendar(data.getAllHomeOwners.Home_Owner[0]) : null
                      }
                      id={id}
                      onSubmit={submitHandler.bind(this, refetch)}
                    />
                  </div>
                  <NoteReminderView
                    {...props}
                    id={id}
                    type={HOMEOWNER}
                    data={!loading ? data.getAllHomeOwners.Home_Owner[0] : null}
                    loading={loading}
                    onSubmit={submitHandler.bind(this, refetch)}
                  />
                </div>

                <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
                  {showEditActivity && (
                    <ActivityButton
                      type={HOMEOWNER}
                      function="UPDATE"
                      item={activity}
                      community_id={
                        !loading
                          ? data.getAllHomeOwners.Home_Owner[0]?.community?.id
                          : null
                      }
                      close={hideEditDrawer}
                      onSubmit={submitHandler.bind(this, refetch)}
                      component={UpdateHomeownerActivityContainer}
                      {...props}
                    />
                  )}
                  {showEditPhoneCall && (
                    <HomeownerCallButton
                      close={hideEditDrawer}
                      id={id}
                      item={activity}
                      showButton={false}
                      function={"UPDATE"}
                      onSubmit={submitHandler.bind(this, refetch)}
                      {...props}
                    />
                  )}

                  <HomeownerDetail
                    data={!loading ? data.getAllHomeOwners.Home_Owner[0] : null}
                    id={id}
                    {...props}
                    onSubmit={submitHandler.bind(this, refetch)}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    )
  }

  const { refetch, loading } = getHomeownerDetail;

  return !loading && (!getHomeownerDetail.getAllHomeOwners?.Home_Owner?.length) ? (
    <NoDataPage
      {...props}
      backgroundImage={logo}
      heading={"Homeowner not found!"}
      content={<div>
        <p>The resource for this homeowner was not found in our records.</p>
        <p className="mt-4 message-reportBug">If it's a bug, please report. <a href={getIssueEmail()} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Report</a></p>
      </div>}
    />
  ) : (
    homeownerDetailsView(
      parseInt(props.match.params.id),
      getHomeownerDetail,
      loading,
      refetch
    )
  );
}

export default HomeownerDetailsView