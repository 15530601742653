import gql from "graphql-tag";

const GET_ACTIVITY_REPORT = gql`
  query getActivityReport(
    $limit: Int
    $pageNum: Int
    $filter: activityReportFilter
  ) {
    getActivityReport(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
    ) {
        activityReports {
            id
            activity_type
            activity_datetime
            csm{
                id
                first_name
                last_name
                is_active
            }
            customer_name
            customer_email,
            customer_email_status,
            customer_phone,
            is_traffic_activity,
            customer_types{
              name
            }
            communities{
                id
                name
                is_active
            }
             customer_origination_area{
              id
              name
            }
            activity_title
            appointment_type
        }
        total_count
    }
  }
`;
export default GET_ACTIVITY_REPORT;
