import React, { useState } from "react";
import CustomButton from "../../../components/custom-button";
import Dialog from "../../../components/dialog/dialog";
import { compose, graphql } from "react-apollo";
import { GET_APPOINTMENT_TYPES } from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import { DEMOTE_HOMEOWNER } from "../../../graphql/mutations/homeowner";
import { goBack } from "../../../utils/helpers";

/**
 * This component is used to render the "Demote to Lead" button and shows a confirmation dialog when clicked.
 * If confirmed, it calls the onSubmit .
 * 
 * @param {Object} props 
 * @param {Boolean} props.showButton to show/hide button
 * @param {JSX.Element} props.btnIcon icon to show
 * @param {Number} props.id id of the homeowner to demote
 */
  const DemoteHomeOwnerToLead = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const toggleShowDialog = () => {
    if (props.showButton) {
      setShowDialog(!showDialog);
    }
  };

  const handleDemoteToLeadConfirmation = async() => {
    setShowDialog(false);

    await props.demoteHomeOwner({
      variables: { homeowner_id: props.homeOwner?.id }
    }).then(({ data }) => {
      const message = data?.demoteHomeOwner?.message 

      message && props.setNotificationProps({
        variables: { open: true, message:  message}
      });

      goBack(props.history)
    })
    
    if (!props.showButton) {
      props.close();
    }
  };
  const handleCancelDemoteConfirmation = () => {
    setShowDialog(false);
  };

  return (
    <div>
      <CustomButton
        size={props.size}
        color={props.color}
        btnIcon={props.btnIcon}
        className={props.className}
        btnValue={props.btnValue}
        onClick={toggleShowDialog}
      />
      <Dialog
        show={showDialog}
        onHide={handleCancelDemoteConfirmation}
        title="Demote to Lead Confirmation"
        body="Are you sure you want to demote this Homeowner to Lead?"
        click={handleDemoteToLeadConfirmation}
        clickname="YES"
        closename="NO"
      />
    </div>
  );
};

export default compose(
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllAppointmentTypes" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(DEMOTE_HOMEOWNER, { name: "demoteHomeOwner" }),
)(DemoteHomeOwnerToLead);

