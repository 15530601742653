import gql from 'graphql-tag';

const GET_LEADS = gql`
query{
  getLeads{
    Leads{
    id
    first_name_1
    last_name_1
    primary_email_1
    secondary_emails_1
    cell_phone_1
    first_name_2
    last_name_2
    primary_email_2
    secondary_emails_2
    primary_email_1_status
    primary_email_2_status
    cell_phone_2
    street_address
    city
    state
    zip
    other_notes
    quality_score
    engagement_score
    communities{
      id
      name
      is_active
    }
    division{
      id
      name
    }
    customer_origination_area {
      id
      name
    }
    created_at
    updated_at}
  }
 }
 `;

export default GET_LEADS;